import {
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { ReactNode } from 'react';
import * as assets from '../../../../assets/';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { useAppDispatch, useAppSelector } from '../../../../reduxStore/redux-hooks';
import {
  updateFileUploadState,
  updateFileSelectionState,
  updateDatasetState,
} from '../../../../reduxStore/slices/fileUploadSlice';
import { ListOfExistingDataset } from './DatasetModal';

interface File {
  name: string;
  ts: number;
}

const ExistingDataset: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { fileUpload, fileSelection, dataset } = useAppSelector((state) => state.fileUpload);

  const handleSelect = (event: SelectChangeEvent<string>, child: ReactNode) => {
    const datasetId = (child as ReactJSXElement).props.value;
    dispatch(
      updateDatasetState({
        selectedDatasetId: datasetId,
      })
    );
    dispatch(
      updateFileSelectionState({
        isDatasetButtonDisabled: false,
      })
    );

    const selectedDataset = dataset['listOfUserOwnedDataset'].find((dataset) => dataset._id === datasetId);
    if (selectedDataset) {
      dispatch(
        updateDatasetState({
          datasetName: selectedDataset.name,
        })
      );
      dispatch(
        updateFileUploadState({
          listOfFilesInSelectedDataset: selectedDataset.files,
        })
      );
      const existingFileNames = selectedDataset.files.map((file: File) => file.name);
      const filesExist = fileUpload['uploadedFiles']
        .filter((file) => existingFileNames.includes(file.name))
        .map((file) => file.name);
      dispatch(
        updateFileUploadState({
          listOfFilteredUploadedFilesExistInDataset: filesExist,
        })
      );
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateFileSelectionState({ isReplaceFileCheckboxChecked: event.target.checked }));
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
      <Typography sx={{ fontWeight: 'bold' }}>{t('fileUpload.existingDataset')}</Typography>
      <FormControl fullWidth variant="standard">
        <Select
          displayEmpty
          value={dataset.selectedDatasetId}
          renderValue={(value: string) => {
            const selectedDataset = dataset['listOfUserOwnedDataset'].find((dataset) => dataset._id === value);
            return (
              <Box
                sx={{
                  padding: '0.25rem 0.5rem',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '1rem',
                }}
              >
                <img
                  style={{
                    width: '1.5rem',
                    height: '1.5rem',
                  }}
                  src={assets.datasets_icon}
                />
                <Typography>{selectedDataset ? selectedDataset.name : t('fileUpload.selectPlaceholder')}</Typography>
              </Box>
            );
          }}
          MenuProps={{
            style: {
              width: 100,
              maxHeight: '17rem',
            },
          }}
          onChange={handleSelect}
        >
          {dataset['listOfUserOwnedDataset'].length === 0 ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            dataset['listOfUserOwnedDataset'].map((data: ListOfExistingDataset) => (
              <MenuItem
                key={data._id}
                value={data._id}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                <Typography sx={{ fontSize: '16px' }}>{data.name}</Typography>
                <Typography
                  sx={{
                    fontSize: '12px',
                    fontWeight: 'bold',
                    color: '#256AFF',
                  }}
                >
                  {`${data.files.length} Files`}
                </Typography>
              </MenuItem>
            ))
          )}
        </Select>
      </FormControl>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
        {fileUpload.listOfFilesInSelectedDataset.length !== 0 && (
          <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography sx={{ color: '#636363' }}>{t('fileUpload.datasetContent')}</Typography>
              <Typography>{fileUpload.listOfFilesInSelectedDataset.length}</Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                maxHeight: '6.5rem',
                overflowY: 'scroll',
                gap: '.25rem',
              }}
            >
              {fileUpload.listOfFilesInSelectedDataset.map((file, key) => (
                <Typography
                  key={key}
                  sx={{
                    padding: '.25rem .25rem',
                    background: '#F6F6F6B2',
                    borderRadius: '.25rem',
                  }}
                >
                  {file.name}
                </Typography>
              ))}
            </Box>
          </>
        )}
        {fileUpload.listOfFilteredUploadedFilesExistInDataset.length !== 0 && (
          <FormControlLabel
            control={
              <Checkbox
                sx={{ userSelect: 'none' }}
                checked={fileSelection.isReplaceFileCheckboxChecked}
                onChange={handleChange}
              />
            }
            label={`${fileUpload.listOfFilteredUploadedFilesExistInDataset} ${t('fileUpload.checkBoxQuestion')}`}
          />
        )}
      </Box>
    </Box>
  );
};

export default ExistingDataset;
