import { SectionDivider } from '../../../staticComponents/StaticHtmlGenerator';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

function UserProfile({ userFullName }: { userFullName: string }) {
  const { t } = useTranslation();
  return (
    <Box sx={{ padding: '30px' }}>
      <Typography textAlign={'left'} fontWeight={800} variant={'h6'}>
        {userFullName}
      </Typography>
      <a href={'/switch-to-admin-account'}>
        <Typography textAlign={'left'} fontWeight={500}>
          {t('rightPanel.switchToAdmin')}
        </Typography>
      </a>
      <SectionDivider sx={{ marginTop: '24px' }} />
    </Box>
  );
}

export default UserProfile;
