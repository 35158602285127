import pdfMake from 'pdfmake/build/pdfmake';
import { Content, TDocumentDefinitions } from 'pdfmake/interfaces';
import { HistoryData } from './History';
import Api from '../../../../data/api/Api';

const Roboto = {
  normal: `${window.location.origin}/fonts/Roboto-Regular.ttf`,
  bold: `${window.location.origin}/fonts/Roboto-Medium.ttf`,
  italics: `${window.location.origin}/fonts/Roboto-Italic.ttf`,
  bolditalics: `${window.location.origin}/fonts/Roboto-MediumItalic.ttf`,
};

pdfMake.fonts = { Roboto };

const openFilePickerAndSaveFile = async (file: Blob) => {
  try {
    const fileHandle = await window.showSaveFilePicker({
      suggestedName: 'history.pdf',
      types: [
        {
          description: 'PDF Format',
          accept: { 'application/pdf': ['.pdf'] },
        },
      ],
    });
    const writableStream = await fileHandle.createWritable();
    await writableStream.write(file);
    await writableStream.close();
  } catch (err) {
    console.error('Error saving PDF file:', err);
  }
};

const createHistoryPdf = async (conversationMessages: HistoryData[]) => {
  const llmModelsList = await Api.listLlmModels();

  const documentTemplate: TDocumentDefinitions = {
    // It sets the document metadata
    info: {
      title: 'Lab45 AI Plaform',
      author: 'Wipro',
      subject: 'LLM Generated Response',
      creator: 'Wipro',
      producer: 'Wipro',
    },

    // It sets the document size
    pageSize: 'A4',

    // It gives default Orientation to page
    pageOrientation: 'portrait',

    // It gives the default page margin to all the pages
    pageMargins: [40, 50],

    // It adds the watermark
    watermark: {
      text: 'Lab45 AI Plaform',
      fontSize: 45,
      color: 'purple',
      opacity: 0.18,
      italics: false,
    },

    // Actual file content
    content: conversationMessages.map((entry) => {
      const codeRegex = new RegExp('```\\S*\\n([\\s\\S]*?)\\n```', 'g');

      // trims extra spaces which is at start and end of text
      entry.content = entry.content.trim();

      // removes codeRegex match and add type of code at the top
      entry.content = entry.content.replace(codeRegex, (match: string, code: string) => {
        return `[ ${match.split('\n')[0].replace('```', '')} ] \n\n${code}`;
      });

      const formattedText = [];
      const title = entry.name.split(':')[0];
      const modalName = llmModelsList.find((modal) => modal.id === title);

      // gives the title to the text i.e either user intials or model name
      formattedText.push({
        text: `${modalName ? modalName.name.toUpperCase() : title.toUpperCase()} : `,
        style: 'type',
      });

      // splits the text based on new line
      const textParts: string[] = entry.content.split('\n');

      // futher processes the text
      const processedText = textParts.map((line) => {
        const asteriskPatternRegex = new RegExp('\\*\\*(.*?)\\*\\*', 'g');

        // removes the asteriskRegex match and make the text line bold
        if (line.match(asteriskPatternRegex)) {
          const textParts: string[] = line.split('**');
          const formattedText = textParts.map((textPart, index) => {
            return index % 2 === 1 ? { text: textPart, style: 'type' } : textPart;
          });

          return {
            text: [...formattedText].concat('\n'),
          };
        }
        // removes the ### match and make the text line bold
        else if (line.startsWith('###')) {
          return {
            text: line.replace('###', '').trim().concat('\n'),
            style: 'type',
          };
        }
        // if nothing matches it keeps the line as it is
        else {
          return { text: line.concat('\n') };
        }
      });

      formattedText.push({ text: processedText });

      return {
        text: formattedText,
        style: 'conversation',
        preserveLeadingSpaces: true,
      };
    }),

    // gives default style to the pages
    defaultStyle: {
      fontSize: 12,
      alignment: 'justify',
      margin: [0, 5],
    },

    // defines style which is reusable
    styles: {
      type: {
        bold: true,
      },
      conversation: {
        margin: [0, 12],
      },
    },

    // gives page number to every page as a footer
    footer: (currentPage: number, pageCount: number): Content => {
      return [
        {
          text: `${currentPage}/${pageCount}`,
          fontSize: 10,
          alignment: 'center',
          margin: [0, 8],
        },
      ];
    },
  };

  const pdfDocGenerator = pdfMake.createPdf(documentTemplate);
  pdfDocGenerator.getBlob(openFilePickerAndSaveFile);
};

export default createHistoryPdf;
