import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UserActionContentState {
  userActionContent: string;
}

const initialState: UserActionContentState = {
  userActionContent: '',
};

const notifyUserActionContentSlice = createSlice({
  name: 'notifyUserActionContent',
  initialState,
  reducers: {
    showUserActionContentOnChat: (state, action: PayloadAction<string>) => {
      state.userActionContent = action.payload;
    },
  },
});

export const { showUserActionContentOnChat } = notifyUserActionContentSlice.actions;

export default notifyUserActionContentSlice.reducer;
