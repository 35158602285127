import { Box } from '@mui/material';
import React from 'react';

function UserAvatar({ initials }: { initials: string }) {
  return (
    <Box
      sx={{
        width: '30px',
        height: '30px',
        borderRadius: '50%',
        display: 'flex',
        boxShadow: '0 2px 1px 0 #00000026',
        cursor: 'pointer',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 auto',
      }}
      component={'div'}
    >
      {initials}
    </Box>
  );
}

export default UserAvatar;
