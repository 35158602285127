import { getTenantName } from '../MsalConfig';
import { Configuration, PopupRequest, SilentRequest } from '@azure/msal-browser';

export const ExternalId = {
  tenantName: getTenantName(),
};

export class AuthConfig {
  getMsalConfig(): Configuration {
    return JSON.parse(localStorage.getItem('msalConfig') as string);
  }

  getRequestConfig(): SilentRequest | PopupRequest {
    const msalConfig = JSON.parse(localStorage.getItem('msalConfig') as string);
    const requestConfig = {
      scopes: [`${msalConfig.auth.clientId}/.default`],
    };
    return requestConfig;
  }
}
