import { Box, Avatar, List, ListItemButton, ListItemAvatar, Typography, ListItemText } from '@mui/material';
import React, { useEffect, useCallback, useState } from 'react';
import { useAppSelector, useAppDispatch } from '../../../../reduxStore/redux-hooks';
import { useTranslation } from 'react-i18next';
import {
  saveSelectedCustomApp,
  saveSowCommitAiQueryResponse,
  saveSowPredictorQueryResponse,
  clearChat,
} from '../../../../reduxStore/slices/CustomAppsSlice';
import * as assets from '../../../../assets';
import {
  SectionDivider,
  CustomAppName,
  AppSkillId,
  customAppsWithSpecificSkills,
  skillIdOfCustomApps,
} from '../../../staticComponents/StaticHtmlGenerator';
import { chatPageNavigation } from '../../../../components/staticComponents/StaticHtmlGenerator';
import { useNavigate } from 'react-router-dom';
import UsePageNavigation from '../../../sharedComponents/customHooks/usePageNavigation/UsePageNavigation';
import Dataset from '../dataset/Dataset';
import { selectedDataSet } from '../../../../reduxStore/slices/DataSetSlice';
import { useSnackbar } from 'notistack';
import Api from '../../../../data/api/Api';

interface CustomAppSkill {
  _id: string;
  allow_all_access: boolean;
  skill_id: string;
  tenant_id: string;
}

interface customAppInfo {
  id: string;
  value: string;
  text: string;
  childId: string;
}

function CustomApps() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { isWelcomePage } = UsePageNavigation();
  const navigate = useNavigate();
  const { selectedCustomApp } = useAppSelector((state) => state.customApps.choosenCustomApp);
  const { enqueueSnackbar } = useSnackbar();
  const [isCustomAppLoading, setIsCustomAppLoading] = useState<boolean>(true);
  const [isCustomAppEnabled, setIsCustomAppEnabled] = useState<boolean>(false);
  const [customSkills, setCustomSkills] = useState<CustomAppSkill[]>([]);

  const appMaps: Record<AppSkillId, customAppInfo> = {
    completion: { id: 'chat-AI', value: 'ChatAI', text: 'Chat AI', childId: 'chat' },
    doc_completion: { id: 'document-AI', value: 'DocumentAI', text: 'Document AI', childId: 'doc' },
    sow_predictor: { id: 'marginal-gpt', value: 'Marginal', text: 'SOW Predictor', childId: 'marginal' },
    sow_commit_ai: { id: 'sow_commit_ai', value: 'sow_commit_ai', text: 'SOW Commit AI', childId: 'sow_commit_ai' },
    sow_commit_acc_ai: {
      id: 'sow_commit_acc_ai',
      value: 'sow_commit_acc_ai',
      text: 'SOW Fin AI',
      childId: 'sow_commit_acc_ai',
    },
    qet_app: { id: 'qet_app', value: 'qet_app', text: 'QET Platform', childId: 'qet_app' },
  };

  const shouldShowDatasets = customAppsWithSpecificSkills.includes(selectedCustomApp);

  const fetchSkillsData = useCallback(async () => {
    try {
      const customAppSkillsList = await Api.fetchSkillsData();
      const appSkills = customAppSkillsList.data as CustomAppSkill[];
      setCustomSkills(appSkills);
      setIsCustomAppLoading(false);
      const filteredSkills = appSkills.map((customKey) => {
        const customAppKey = customKey.skill_id as AppSkillId; // Cast key to AppSkillId type
        const isSkillEnabled = skillIdOfCustomApps.includes(customAppKey);

        if (isSkillEnabled) {
          setIsCustomAppEnabled(true);
        }
      });
    } catch (err) {
      enqueueSnackbar((err as Error).toString());
    }
  }, [enqueueSnackbar]);

  useEffect(() => {
    fetchSkillsData();
  }, []);

  const renderCustomApps = () => {
    return Object.keys(appMaps).map((customKey) => {
      const customAppKey = customKey as AppSkillId; // Cast key to appName type
      if (customAppKey !== AppSkillId.Completion && customAppKey !== AppSkillId.DocCompletion) {
        const isSkillEnabled = customSkills.some((app: CustomAppSkill) => app.skill_id === customAppKey);

        // Only render enabled skills
        if (isSkillEnabled) {
          return (
            <ListItemButton
              key={customAppKey}
              sx={{
                padding: '5px',
                paddingLeft: '0px',
              }}
              onClick={() => makeCustomAppActive(appMaps[customAppKey].text)}
            >
              <ListItemAvatar sx={{ minWidth: '50px' }}>
                <Avatar alt="default agent" src={assets.ai360Agent} />
              </ListItemAvatar>
              <ListItemText
                primary={appMaps[customAppKey].text} // Use the text property here
                sx={{ padding: '8px 0px', margin: '0px' }}
              />
            </ListItemButton>
          );
        }
      }
    });
  };

  const getCustomAppSkill = (customApp: string) => {
    switch (customApp) {
      case CustomAppName.SOWCommitAI:
        return AppSkillId.SOWCommitAI;
      case CustomAppName.SOWPredictor:
        return AppSkillId.SOWPredictor;
      case CustomAppName.SOWFinAI:
        return AppSkillId.SOWCommitAccAI;
      default:
        return AppSkillId.SOWPredictor;
    }
  };

  // Function to set the active customapp
  const makeCustomAppActive = (customApp: string) => {
    dispatch(saveSowCommitAiQueryResponse([]));
    dispatch(saveSowPredictorQueryResponse({}));
    dispatch(clearChat(true));

    dispatch(
      saveSelectedCustomApp({
        isCustomAppSelected: true,
        selectedCustomApp: customApp,
        selectedCustomAppSkill: getCustomAppSkill(customApp),
        welcomeMessage: t('rightPanel.customapps.activateCustomApp', { selectedCustomApp: customApp }),
      })
    );
    dispatch(
      selectedDataSet({
        activeDataSetName: '',
        activeDataSetId: '',
      })
    );
    if (customApp === CustomAppName.QETPlatform) {
      window.open('https://platform.wiprocms.com/', '_blank');
    }
    if (isWelcomePage) {
      navigate(chatPageNavigation);
    }
  };

  //to clear the messages on chatbox
  useEffect(() => {
    dispatch(clearChat(true));
  }, [clearChat]);

  return (
    <Box id={'customApp-2nd-level-content'}>
      <Box className="active-customApp" marginBottom={'25px'}>
        <Typography color={'#6F6C6C'} fontWeight={700} fontSize={'14px'}>
          {t('rightPanel.customapps.activeApp')}
        </Typography>

        <Box>
          {!selectedCustomApp ? (
            <Typography color={'#A6A6A6'} fontWeight={400} fontSize={'16px'} marginTop={'10px'}>
              {t('rightPanel.customapps.noActiveCustomApp')}
            </Typography>
          ) : (
            <List>
              <ListItemButton
                key={selectedCustomApp}
                className="customApp-list"
                sx={{
                  padding: '5px',
                  paddingLeft: '0px',
                  width: '100%',
                }}
              >
                <ListItemAvatar sx={{ alignSelf: 'start', minWidth: '50px' }}>
                  <Avatar className="avatar" alt="default agent" src={assets.ai360Agent} />
                </ListItemAvatar>
                <Box width={'100%'}>
                  <Typography color={'#0B0C0C'} fontWeight={700} fontSize={'16px'}>
                    {selectedCustomApp}
                  </Typography>
                </Box>
              </ListItemButton>
            </List>
          )}
        </Box>
      </Box>

      <Box marginBottom={'5px'}>
        <Typography color={'#6F6C6C'} fontWeight={700} fontSize={'14px'} sx={{ gap: '3rem' }}>
          {t('rightPanel.customapps.builtForYou')}
        </Typography>
        <Box maxHeight={'13rem'} overflow={'auto'}>
          <>
            {isCustomAppLoading ? (
              // Display default apps message before fetching data
              <Typography color={'#A6A6A6'} fontWeight={400} fontSize={'16px'} marginTop={'10px'}>
                {t('rightPanel.customapps.noCustomAppLoaded')}
              </Typography>
            ) : // Once loading is complete, check if renderCustomApps returns any elements
            isCustomAppEnabled ? (
              <List>
                <Box className="apps-list">{renderCustomApps()}</Box>
              </List>
            ) : (
              // If renderCustomApps returns an empty array,displays the default message
              <Typography color={'#A6A6A6'} fontWeight={400} fontSize={'16px'} marginTop={'10px'}>
                {t('rightPanel.customapps.noCustomAppLoaded')}
              </Typography>
            )}
          </>
        </Box>
      </Box>
      <SectionDivider sx={{ marginTop: '2px', marginLeft: '0px', marginRight: '0px' }} />
      {shouldShowDatasets && (
        <>
          <Dataset />
        </>
      )}
    </Box>
  );
}

export default CustomApps;
