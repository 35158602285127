import { Box, CircularProgress, FormControl, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import * as assets from '../../../../../../../assets';
import { useTranslation } from 'react-i18next';
import { ListOfExistingDataset } from '../../../../../../../components/rightPanel/subpanel/dataset/DatasetModal';
import Api from '../../../../../../../data/api/Api';
import { useSnackbar } from 'notistack';
import { AttachDataset } from '../AttachDataset';
import AttachNewDataset from '../attach-new-dataset/AttachNewDataset';
import { AppSkillId } from '../../../../../../../components/staticComponents/StaticHtmlGenerator';

function AttachExistingDataset({ attachDataset, setIsAgentCreateInProcess, attachDatasetTopK }: AttachDataset) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [datasetList, setDatasetList] = useState<ListOfExistingDataset[]>([]);
  const [selectedDataset, setSelectedDataset] = useState<ListOfExistingDataset>();

  useEffect(() => {
    fetchDatasets();
  }, []);

  // Function to fetch the list of existing datasets from the API
  const fetchDatasets = async () => {
    try {
      const listOfExistingDataset = (await Api.listDatasets(true, true, AppSkillId.DocCompletion))
        .data as ListOfExistingDataset[];
      setDatasetList(listOfExistingDataset);
    } catch (error) {
      enqueueSnackbar((error as Error).toString(), { variant: 'error' });
    }
  };

  // Function to handle the selection of a dataset from the dropdown
  const handleDatasetSelection = (event: SelectChangeEvent) => {
    const selectedDataset = datasetList.find((dataset) => dataset._id === event.target.value);
    if (selectedDataset) {
      attachDataset(selectedDataset._id);
      setSelectedDataset(selectedDataset);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', paddingTop: '20px', width: '615px' }}>
      <Typography sx={{ fontWeight: 700 }}>{t('fileUpload.existingDatasetLabel')}</Typography>
      <FormControl fullWidth variant="standard">
        <Select
          displayEmpty
          value={selectedDataset && selectedDataset._id}
          renderValue={(value: string) => {
            const selectedDataset = datasetList.find((dataset) => dataset._id === value);
            return (
              <Box
                sx={{
                  padding: '0.25rem 0.5rem',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '1rem',
                }}
              >
                <img
                  style={{
                    width: '1.5rem',
                    height: '1.5rem',
                  }}
                  src={assets.datasets_icon}
                />
                <Typography>{selectedDataset ? selectedDataset.name : t('fileUpload.selectPlaceholder')}</Typography>
              </Box>
            );
          }}
          MenuProps={{
            style: {
              maxHeight: '17rem',
            },
          }}
          onChange={handleDatasetSelection}
        >
          {datasetList.length === 0 ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            datasetList.map((dataset: ListOfExistingDataset) => (
              <MenuItem
                key={dataset._id}
                value={dataset._id}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                <Typography sx={{ fontSize: '16px' }}>{dataset.name}</Typography>
                <Typography
                  sx={{
                    fontSize: '12px',
                    fontWeight: 700,
                    color: '#256AFF',
                  }}
                >
                  {`${dataset.files.length} Files`}
                </Typography>
              </MenuItem>
            ))
          )}
        </Select>
      </FormControl>

      {/* If a dataset is selected and it has files, show the dataset content */}
      {selectedDataset && selectedDataset.files.length !== 0 && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography sx={{ color: '#636363' }}>{t('fileUpload.datasetContent')}</Typography>
            <Typography>{selectedDataset && selectedDataset.files.length}</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              maxHeight: '6.5rem',
              overflowY: 'scroll',
              gap: '.25rem',
            }}
          >
            {selectedDataset &&
              selectedDataset.files.map((file: File, index) => (
                <Typography
                  key={index}
                  sx={{
                    padding: '.25rem .25rem',
                    background: '#F6F6F6B2',
                    borderRadius: '.25rem',
                  }}
                >
                  {file.name}
                </Typography>
              ))}
          </Box>
        </Box>
      )}

      {selectedDataset && (
        <AttachNewDataset
          attachDataset={attachDataset}
          setIsAgentCreateInProcess={setIsAgentCreateInProcess}
          selectedExistingDataset={selectedDataset}
          attachDatasetTopK={attachDatasetTopK}
        />
      )}
    </Box>
  );
}

export default AttachExistingDataset;
