import logo from '../assets/ai_logo.svg';

interface LogoProps {
  externalId: string;
}

function Logo({ externalId }: LogoProps) {
  return (
    <>
      {externalId === 'wipro' ? (
        <img src={logo} alt="logo" className="logo" data-testid="logo" />
      ) : (
        <div>{externalId}</div>
      )}
    </>
  );
}

export default Logo;
