import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../reduxStore/redux-hooks';
import {
  updateFileUploadState,
  updateFileSelectionState,
  updateDatasetState,
} from '../../../../reduxStore/slices/fileUploadSlice';
import { Radio, RadioGroup, FormControlLabel, FormControl, SelectChangeEvent } from '@mui/material';

interface RadioSelectProps {
  setdatasetRadioSelect: (radioSelectMessage: string) => void;
}

const RadioSelect: React.FC<RadioSelectProps> = ({ setdatasetRadioSelect }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleDatasetSelect = (event: SelectChangeEvent<HTMLInputElement>) => {
    const selectedRadioButtonValue = (event.target as HTMLInputElement).value;
    setdatasetRadioSelect(selectedRadioButtonValue);
    if (selectedRadioButtonValue === 'New Dataset') {
      dispatch(
        updateFileSelectionState({
          isDatasetButtonDisabled: false,
        })
      );
    } else {
      dispatch(
        updateFileUploadState({
          listOfFilesInSelectedDataset: [],
          listOfFilteredUploadedFilesExistInDataset: [],
        })
      );

      dispatch(
        updateDatasetState({
          selectedDatasetId: '',
        })
      );

      dispatch(
        updateFileSelectionState({
          isReplaceFileCheckboxChecked: false,
          isDatasetButtonDisabled: true,
        })
      );
    }
  };

  return (
    <FormControl>
      <RadioGroup
        row
        aria-labelledby="dataset-row-radio-buttons-group-label"
        name={t('fileUpload.radioGroup')}
        defaultValue={t('fileUpload.newDataset')}
        sx={{ display: 'flex', gap: '2rem' }}
        onChange={handleDatasetSelect}
      >
        <FormControlLabel value={t('fileUpload.newDataset')} control={<Radio />} label={t('fileUpload.newDataset')} />
        <FormControlLabel
          value={t('fileUpload.existingDataset')}
          control={<Radio />}
          label={t('fileUpload.existingDataset')}
        />
      </RadioGroup>
    </FormControl>
  );
};

export default RadioSelect;
