import React, { useContext } from 'react';
import { ActiveNavItemContext } from '../../RightPanelContext';
import { Box, Typography } from '@mui/material';
import * as assets from '../../../../assets';
import { usePortrait } from '../../../staticComponents/StaticHtmlGenerator';

/**
 * Wipro logo
 * @constructor
 */
export function WiproLogo() {
  const { activeNavItem } = useContext(ActiveNavItemContext);
  const subPanelOpen = activeNavItem !== null;
  const styles = {
    width: subPanelOpen ? '352px' : '65px',
    borderTopLeftRadius: subPanelOpen ? '0' : '8px',
    textAlign: subPanelOpen ? 'left' : 'center',
  };
  const portrait = usePortrait();
  return (
    <Box
      id="wipro-logo"
      sx={{
        display: {
          xs: portrait ? 'block' : 'none',
          md: 'none',
        },
        height: '40px',
        minHeight: '40px',
        background: 'linear-gradient(90deg, #095EFF, #A930FF)',
        position: 'absolute',
        right: '0',
        top: '24px',
        zIndex: '1300',
        ...styles,
      }}
    >
      <Box
        sx={{
          width: '50px',
          height: '25px',
          top: '7.5px',
          position: 'relative',
          left: subPanelOpen ? '20px' : '0',
        }}
        component={'img'}
        src={assets.wiproLogo}
        id={'wipro-logo-img'}
      />
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: 500,
          color: '#ffffff',
          display: 'inline-block',
          marginLeft: '30px',
        }}
      >
        {subPanelOpen ? 'GenAI Platform' : ''}
      </Typography>
    </Box>
  );
}
