import React from 'react';
import { Box } from '@mui/material';
import ImageButton from '../../components/sharedComponents/ImageButton';
import * as assets from '../../assets';

interface SendChatProps {
  onSendChatPressed: () => Promise<void>;
  disabled: boolean;
}

const SendChat: React.FC<SendChatProps> = ({ onSendChatPressed, disabled }) => {
  const handleCLick = async () => {
    await onSendChatPressed();
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        left: disabled ? '7px' : '-17px',
      }}
    >
      <ImageButton disabled={disabled} srcFile={assets.sendChat} className="send-chat" handleClick={handleCLick} />
    </Box>
  );
};

export default SendChat;
