import React, { useState } from 'react';
import {
  Dialog,
  Button,
  Box,
  Typography,
  DialogActions,
  DialogTitle,
  Divider,
  DialogContent,
  DialogContentText,
  TextField,
  Chip,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Api from '../../../../data/api/Api';
import { enqueueSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { datasetSharedUser } from './DatasetList';
import { useAppDispatch } from '../../../../reduxStore/redux-hooks';
import { showUserActionContentOnChat } from '../../../../reduxStore/slices/NotifyUserActionContentSlice';

interface User {
  id: string;
  displayName: string;
  email: string;
}

interface ShareDatasetProps {
  openShareDataset: boolean;
  closeShareDataset: () => void;
  datasetId: string;
  sharedListDataset: datasetSharedUser[];
  isNavigatedThroughManageShare?: boolean;
  datasetName: string;
}

interface GraphAPIResponse {
  data: {
    '@odata.context': string;
    value: User[];
  };
}

export interface DatasetSharedUserDetails {
  id: string;
  name: string;
}

const ShareDataset: React.FC<ShareDatasetProps> = ({
  openShareDataset,
  closeShareDataset,
  datasetId,
  sharedListDataset,
  isNavigatedThroughManageShare,
  datasetName,
}) => {
  const [userEmails, setUserEmails] = useState<string>();
  const [isDatasetLoading, setIsDatasetLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const storeEmails = (emails: string) => {
    setUserEmails(emails);
  };

  const handleDatasetShare = async () => {
    if (userEmails) {
      try {
        setIsDatasetLoading(true);
        const listOfUserEmails = userEmails
          .split(',')
          .map((email) => email.trim())
          .filter((email) => email);

        const datasetSharedUserList: DatasetSharedUserDetails[] = [];

        const emailValidationPromises = listOfUserEmails.map(async (email) => {
          const isEmailValid = (await Api.fetchUserByEmail(email)) as GraphAPIResponse;
          if (!isEmailValid.data.value.length) {
            throw new Error(`Invalid email: ${email}`);
          }
          datasetSharedUserList.push({
            id: isEmailValid.data.value[0].id,
            name: isEmailValid.data.value[0].displayName,
          });
          return email;
        });
        await Promise.all(emailValidationPromises);
        await Api.addShareDataset(
          datasetId,
          datasetSharedUserList.map((user) => user.id)
        );
        if (isNavigatedThroughManageShare) {
          dispatch(
            showUserActionContentOnChat(
              t('rightPanel.dataset.shareDataset.activateDatasetSharedWithNewUsers', { fileName: datasetName })
            )
          );
        }
        closeShareDataset();
        setIsDatasetLoading(false);
      } catch (error) {
        setIsDatasetLoading(false);
        enqueueSnackbar((error as Error).toString(), { variant: 'error' });
      }
    }
  };

  return (
    <Dialog fullWidth sx={{ borderRadius: '4px' }} open={openShareDataset} aria-labelledby="responsive-dialog-title">
      <Box padding={'40px'}>
        <DialogTitle id="responsive-dialog-title" textAlign={'center'}>
          <Typography fontSize={24} fontWeight={700}>
            {t('rightPanel.dataset.shareDataset.title')}
          </Typography>
          <Divider sx={{ padding: '10px' }} />
        </DialogTitle>

        <DialogContent sx={{ paddingTop: '20px !important' }}>
          <DialogContentText paddingBottom={'5px'} fontWeight={700} color={'#0B0C0C'}>
            {t('rightPanel.dataset.shareDataset.shareDatasetwith')}
          </DialogContentText>
          <TextField
            id="email"
            name="email"
            type="email"
            fullWidth
            variant="standard"
            placeholder={t('rightPanel.dataset.shareDataset.placeholder')}
            onChange={(event) => storeEmails(event.target.value)}
          />
          {sharedListDataset.length > 0 && (
            <Typography sx={{ color: '#7E8286', fontSize: '12px', fontWeight: '500', paddingTop: '10px' }}>
              {t('rightPanel.dataset.shareDataset.shareWithMembers')}
            </Typography>
          )}

          {sharedListDataset.map((item) => (
            <Chip
              label={item.name}
              key={item.id}
              sx={{
                margin: '5px 0px',
                marginRight: '10px',
                padding: '4px 10px',
                borderRadius: '4px',
                backgroundColor: '#3F3F3F',
                color: '#F2F2F2',
                position: 'relative',
              }}
            />
          ))}
        </DialogContent>
        <DialogActions sx={{ paddingTop: '40px' }}>
          <Button
            sx={{ textTransform: 'none', textDecoration: 'underline' }}
            autoFocus
            size="small"
            onClick={closeShareDataset}
          >
            {t('rightPanel.dataset.shareDataset.cancel')}
          </Button>
          <LoadingButton
            sx={{ textTransform: 'none', backgroundColor: '#793196' }}
            variant="contained"
            loading={isDatasetLoading}
            onClick={handleDatasetShare}
          >
            {t('rightPanel.dataset.shareDataset.share')}
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
export default ShareDataset;
