//Removed useCallback because getFormattedDateAndTime doesn’t need to be a hook, it doesn’t rely on any component state or lifecycle.
//We can simply define it as a regular function instead of using useCallback.
export const getFormattedDateAndTime = (last_updated: number) => {
  const date = new Date(last_updated * 1000);
  const day = date.getDate().toString().padStart(2, '0');
  const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date);
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  return {
    dateString: `${day} ${month} ${year}`,
    timeString: ` ${hours}:${minutes}`,
  };
};
