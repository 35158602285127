import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ChoosenSelectedCustomApp {
  isCustomAppSelected: boolean;
  selectedCustomApp: string;
  selectedCustomAppSkill: string;
  welcomeMessage: string;
}
export interface SowCommitAiQueryResponse {
  row: {
    GenAI: string;
    title: string;
  };
}
export interface SowPredictorQueryResponse {
  [key: string]: {};
}

const initialState = {
  choosenCustomApp: {
    isCustomAppSelected: false,
    selectedCustomApp: '',
    selectedCustomAppSkill: '',
    welcomeMessage: '',
  },
  responseTable: {
    sowQueryResponse: '',
    selectedCustomAppSkills: '',
  },
  sowCommitAiQueryResponse: [] as SowCommitAiQueryResponse[],
  sowPredictorQueryResponse: {},
  shouldClearChat: false,
};

export const customAppsSlice = createSlice({
  name: 'customApps',
  initialState,
  reducers: {
    saveSelectedCustomApp: (state, action: PayloadAction<ChoosenSelectedCustomApp>) => {
      state.choosenCustomApp = action.payload;
    },
    saveSowCommitAiQueryResponse: (state, action: PayloadAction<SowCommitAiQueryResponse[]>) => {
      state.sowCommitAiQueryResponse = action.payload;
    },
    saveSowPredictorQueryResponse: (state, action: PayloadAction<SowPredictorQueryResponse>) => {
      state.sowPredictorQueryResponse = action.payload;
    },
    clearChat: (state, action: PayloadAction<boolean>) => {
      state.shouldClearChat = action.payload;
    },
  },
});
// Action creators are generated for each case reducer function
export const { saveSelectedCustomApp, saveSowCommitAiQueryResponse, saveSowPredictorQueryResponse, clearChat } =
  customAppsSlice.actions;

export default customAppsSlice.reducer;
