import BasicPopover from '../../../../sharedComponents/BasicPopover';
import ImageButton from '../../../../sharedComponents/ImageButton';
import {
  Avatar,
  Box,
  CircularProgress,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Api from '../../../../../data/api/Api';
import { useAppDispatch, useAppSelector } from '../../../../../reduxStore/redux-hooks';
import { activeAgent } from '../../../../../reduxStore/slices/AgentsSlice';
import EditAgent from '../editAgent/EditAgent';
import * as assets from '../../../../../assets';
import { useTranslation } from 'react-i18next';
import { showUserActionContentOnChat } from '../../../../../reduxStore/slices/NotifyUserActionContentSlice';
import { buttonStyle } from '../../dataset/Dataset';
import { chatPageNavigation } from '../../../../../components/staticComponents/StaticHtmlGenerator';
import { useNavigate } from 'react-router-dom';
import UsePageNavigation from '../../../../sharedComponents/customHooks/usePageNavigation/UsePageNavigation';

export interface MyAgentsData {
  name: string;
  _id: string;
  model_name: string;
  owners: string[];
  acl?: {
    users: string[];
  };
}

interface MyAgentsDataResponse {
  data: MyAgentsData[];
  response: Response;
}

const MyAgents = () => {
  const [myAgents, setMyAgents] = useState<MyAgentsData[]>([]);
  const [filteredMyAgentsList, setFilteredMyAgentsList] = useState<MyAgentsData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const { activeAgentId } = useAppSelector((state) => state.agents.activeAgent);
  const { t } = useTranslation();
  const hiddenMyAgentsList = ['logoassistant', 'bing', 'imageassistant'];
  const { isWelcomePage } = UsePageNavigation();
  const navigate = useNavigate();

  // UseEffect hook to fetch the list of agents on component mount
  useEffect(() => {
    fetchMyAgentsList();
  }, []);

  // Function to fetch the list of agents from the API
  const fetchMyAgentsList = () => {
    Api.getMyAgentsList()
      .then(async (response) => {
        setLoading(false);
        const myAgentsListResp = response as MyAgentsDataResponse;
        myAgentsListResp.data = myAgentsListResp.data.filter(
          (agent) => !hiddenMyAgentsList.includes(agent.name.toLowerCase())
        );
        const sortedAgents = myAgentsListResp.data.sort((a, b) => a.name.localeCompare(b.name));
        const llmModels = await Api.listLlmModels();
        // Update the model_name in sortedAgents
        sortedAgents.forEach((agent) => {
          llmModels.forEach((model) => {
            if (agent.model_name === model.id) {
              agent.model_name = model.name; // Update with the corresponding name
            }
          });
        });
        setMyAgents(sortedAgents);
        setFilteredMyAgentsList(sortedAgents);
      })
      .catch((error) => {
        setLoading(false);
        console.error('Error in fetching my agents list:', error);
      });
  };

  // Function to set the active agent
  const makeAgentActive = (agentData: MyAgentsData) => {
    dispatch(
      activeAgent({
        activeAgentName: agentData.name,
        activeAgentId: agentData._id,
        activeAgentModelName: agentData.model_name,
        owners: agentData.owners,
        acl: agentData.acl,
      })
    );
    dispatch(showUserActionContentOnChat(t('rightPanel.agent.activateAgent', { activeAgentName: agentData.name })));
    if (isWelcomePage) {
      navigate(chatPageNavigation);
    }
  };

  // Function to refresh the list of agents
  const refreshMyAgentsList = () => {
    fetchMyAgentsList();
  };

  // Function to filter the list of agents based on the search query
  const filterMyAgentsList = (agentName: string) => {
    setFilteredMyAgentsList(myAgents.filter((agent) => agent.name.toLowerCase().includes(agentName.toLowerCase())));
  };

  const renderEditAgentPopover = (agent: MyAgentsData) => {
    return (
      <BasicPopover
        srcComponent={
          <Box className="hover-showDotsButton">
            <ImageButton srcFile={assets.dots} style={buttonStyle} />
          </Box>
        }
        contentComponent={<EditAgent agent={agent} isActiveAgent={false} refreshMyAgentsList={refreshMyAgentsList} />}
      />
    );
  };

  return (
    <Box>
      <Typography color={'#6F6C6C'} fontWeight={700} fontSize={'14px'}>
        {t('rightPanel.agent.myAgents')}
      </Typography>
      {loading ? (
        <CircularProgress size={20} />
      ) : (
        <Box>
          {!myAgents.length ? (
            <Typography color={'#A6A6A6'} fontWeight={400} fontSize={'16px'} marginTop={'10px'}>
              {t('rightPanel.agent.noCustomAgents')}
            </Typography>
          ) : (
            <>
              <TextField
                label={t('rightPanel.agent.searchAgentName')}
                variant="standard"
                id="searchAgent"
                onChange={(event) => filterMyAgentsList(event.target.value)}
                size="small"
                fullWidth
              />
              <List sx={{ maxHeight: '16rem', overflow: 'auto' }}>
                {filteredMyAgentsList.map((agent) => (
                  <Box key={agent._id} className="agents-list">
                    <ListItemButton
                      disabled={activeAgentId === agent._id}
                      sx={{
                        padding: '5px',
                        paddingLeft: '0px',
                      }}
                    >
                      <ListItemAvatar sx={{ minWidth: '50px' }} onClick={() => makeAgentActive(agent)}>
                        <Avatar alt="default agent" src={assets.ai360Agent} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={agent.name}
                        sx={{ padding: '8px 0px', margin: '0px' }}
                        onClick={() => makeAgentActive(agent)}
                      />
                      {activeAgentId !== agent._id && (
                        <span className="buttonShowOnHover">{renderEditAgentPopover(agent)}</span>
                      )}
                    </ListItemButton>
                  </Box>
                ))}
                {filteredMyAgentsList.length === 0 && <Typography color="red">{t('rightPanel.noResult')}</Typography>}
              </List>
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

export default MyAgents;
