import {
  Box,
  Icon,
  Input,
  InputLabel,
  LinearProgress,
  LinearProgressProps,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useRef } from 'react';
import * as assets from '../../../../../../../assets';
import { useTranslation } from 'react-i18next';
import Api from '../../../../../../../data/api/Api';
import { useSnackbar } from 'notistack';
import { AttachDataset } from '../AttachDataset';
import { supportedFileTypes } from '../../../../../../../components/rightPanel/subpanel/dataset/DatasetModal';
import InfoIcon from '@mui/icons-material/Info';
import NumberInput from '../../../../../../../components/sharedComponents/numberInput/NumberInput';
import { topKtooltipPoints } from '../../../../../../../components/staticComponents/StaticHtmlGenerator';
import { AppSkillId } from '../../../../../../../components/staticComponents/StaticHtmlGenerator';

function AttachNewDataset({
  attachDataset,
  setIsAgentCreateInProcess,
  selectedExistingDataset,
  attachDatasetTopK,
}: AttachDataset) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [uploadedFilesInfo, setUploadedFilesInfo] = React.useState<
    { name: string; size: string; progress: number; timeLeft: string }[]
  >([]);
  const newDatasetName = useRef<string>('');
  const newDatasetDescription = useRef<string>('');
  const newDatasetId = useRef<string>('');

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files) {
      const checkIfUploadedFilesSupport = Array.from(files).every((file) => supportedFileTypes.includes(file.type));
      if (!checkIfUploadedFilesSupport) {
        enqueueSnackbar(t('fileUpload.supportedFiles'), { variant: 'error' });
        return; //Return and stop processing further if unsupported files are uploaded.
      }

      const isFilesSizeAndLengthIsValid = validateFilesLengthAndSize(Array.from(files));
      if (isFilesSizeAndLengthIsValid) {
        storeNewDatasetFiles(Array.from(files));
        createAndPrepareDataset(Array.from(files));
      }
    }
  };

  const validateFilesLengthAndSize = (files: File[]) => {
    const currentFilesCount = uploadedFilesInfo.length;
    const currentFilesTotalSize = uploadedFilesInfo.reduce((total, file) => {
      return total + parseFloat(file.size);
    }, 0);

    const newFilesCount = files.length;
    const newFilesTotalSize = files.reduce((total, file) => {
      return total + file.size / (1024 * 1024);
    }, 0);

    const totalFilesSize = currentFilesTotalSize + newFilesTotalSize;
    const totalFilesCount = currentFilesCount + newFilesCount;
    if (totalFilesCount > 10) {
      enqueueSnackbar(t('fileUpload.uploadMax10Files'), { variant: 'error' });
      return false;
    }
    if (totalFilesSize > 100) {
      enqueueSnackbar(t('fileUpload.uploadMax100MB'), { variant: 'error' });
      return false;
    }
    return true;
  };

  const createAndPrepareDataset = async (files: File[]) => {
    try {
      setIsAgentCreateInProcess(true);
      const totalApiCallsForCreateAndPrepareDataset = 4;
      const newlyCreatedDatasetId = selectedExistingDataset
        ? selectedExistingDataset._id
        : uploadedFilesInfo.length > 0
          ? newDatasetId.current
          : (await Api.createDataset(files[0].name)).data._id;
      calculateFileUploadProgress(totalApiCallsForCreateAndPrepareDataset, 1, files);
      newDatasetId.current = newlyCreatedDatasetId;
      await Api.uploadDataset(newlyCreatedDatasetId, files);
      calculateFileUploadProgress(totalApiCallsForCreateAndPrepareDataset, 2, files);
      const prepare = await Api.prepareDataset(newlyCreatedDatasetId, AppSkillId.DocCompletion);
      let workflowData = prepare.data;
      calculateFileUploadProgress(totalApiCallsForCreateAndPrepareDataset, 3, files);
      const delay = 1000 * (files.length || 1);
      while (workflowData.status !== 'Completed') {
        const workflow = await Api.getWorkflowForDataset(newlyCreatedDatasetId, workflowData._id);
        if (workflow.data) {
          workflowData = workflow.data;
        }
        if (workflowData.status === 'Failed') {
          return;
        }
        await new Promise((r) => setTimeout(r, delay));
      }
      calculateFileUploadProgress(totalApiCallsForCreateAndPrepareDataset, 4, files);
      setIsAgentCreateInProcess(false);
      attachDataset(newDatasetId.current);
    } catch (error) {
      enqueueSnackbar((error as Error).toString());
    }
  };

  const storeNewDatasetFiles = (files: File[]) => {
    files.forEach((file) => {
      const newFileInfo = {
        name: file.name,
        size: `${(file.size / (1024 * 1024)).toFixed(2)} MB`, // Convert size to MB
        progress: 0,
        timeLeft: 'Calculating...',
      };

      setUploadedFilesInfo((previuos) => [...previuos, newFileInfo]);
    });
  };

  const calculateFileUploadProgress = (
    totalApiCallsForCreateAndPrepareDataset: number,
    completedCalls: number,
    files: File[]
  ) => {
    const progress = Math.min((completedCalls / totalApiCallsForCreateAndPrepareDataset) * 100, 100);
    setUploadedFilesInfo((previuos) => {
      const listOfUploadedFiles = [...previuos];
      const remainingCalls = totalApiCallsForCreateAndPrepareDataset - completedCalls;
      const estimatedTimePerCall = 2500; // Random time for each call
      const remainingTime = Math.ceil((remainingCalls * estimatedTimePerCall) / 1000); // Convert to seconds

      const startIndex = Math.max(0, listOfUploadedFiles.length - files.length);
      for (let i = startIndex; i < listOfUploadedFiles.length; i++) {
        listOfUploadedFiles[i].progress = progress;
        listOfUploadedFiles[i].timeLeft = `${remainingTime}`;
      }

      return listOfUploadedFiles;
    });
  };

  const updateDatasetDetails = () => {
    const newDatasetDetails = {
      name: newDatasetName.current ? newDatasetName.current : uploadedFilesInfo[0].name,
      ...(newDatasetDescription.current && { description: newDatasetDescription.current }),
    };
    try {
      setIsAgentCreateInProcess(true);
      Api.updateDataset(newDatasetId.current, newDatasetDetails);
    } catch (error) {
      console.error(error);
    }
    setIsAgentCreateInProcess(false);
  };

  const handleWebLinks = async (webLinks: string) => {
    if (webLinks) {
      try {
        setIsAgentCreateInProcess(true);
        const crawlResponse = await Api.crawlWebsite(newDatasetId.current, webLinks);
        if (crawlResponse.error) {
          throw new Error(`${crawlResponse.error}`);
        }
        await Api.prepareDataset(newDatasetId.current, AppSkillId.DocCompletion);
      } catch (error) {
        enqueueSnackbar((error as Error).toString(), { variant: 'error' });
      }
      setIsAgentCreateInProcess(false);
    }
  };

  const handleDeleteFile = async (fileName: string, selectedFileIndex: number) => {
    setUploadedFilesInfo((previous) => previous.filter((_, index) => index !== selectedFileIndex));
    if (uploadedFilesInfo.length === 1 && !selectedExistingDataset) {
      try {
        await Api.deleteDataset(newDatasetId.current);
      } catch (error) {
        enqueueSnackbar((error as Error).toString(), { variant: 'error' });
      }
    } else {
      try {
        const deleteDatasetResourceResponse = await Api.deleteDatasetResource(newDatasetId.current, [fileName]);
        if (deleteDatasetResourceResponse.error) {
          throw new Error(`${deleteDatasetResourceResponse.error}`);
        }
      } catch (error) {
        enqueueSnackbar((error as Error).toString(), { variant: 'error' });
      }
    }
  };

  const LinearProgressWithLabel = (props: LinearProgressProps & { value: number }) => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%' }}>
          <LinearProgress
            variant="determinate"
            {...props}
            sx={{
              backgroundColor: 'lightgray',
              '& .MuiLinearProgress-bar': {
                background: 'linear-gradient(90deg, #1C6DFF 0%, #C025FF 70.06%)',
              },
            }}
          />
        </Box>
      </Box>
    );
  };

  return (
    <Box sx={{ width: '615px' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <label htmlFor="upload-files">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '0.5rem',
              marginTop: '20px',
              border: '1px dashed var(--Supportive-Notification, #4282FE)',
              borderRadius: '8px',
              alignItems: 'center',
              padding: '14px',
              cursor: 'pointer',
            }}
          >
            <img src={assets.fileUploadIcon} alt="file upload" />
            <Typography>{t('rightPanel.agent.createAgent.attachDataset.dropFiles')}</Typography>
            <Typography sx={{ color: '#7E8286' }}>{t('rightPanel.agent.createAgent.attachDataset.or')}</Typography>
            <Input
              style={{ display: 'none' }}
              id="upload-files"
              type="file"
              inputProps={{
                multiple: true,
                accept: supportedFileTypes,
              }}
              onChange={handleFileChange}
            />

            <Typography sx={{ color: '#256AFF', cursor: 'pointer', fontWeight: 700, textDecorationLine: 'underline' }}>
              {t('rightPanel.agent.createAgent.attachDataset.browse')}
            </Typography>
          </Box>
        </label>
      </Box>

      {uploadedFilesInfo.length > 0 && (
        <Box sx={{ padding: '20px 0px' }}>
          {uploadedFilesInfo.map((fileInfo, index) => (
            <Box
              key={index}
              sx={{
                background: '#F5F8FF',
                borderRadius: '4px',
                display: 'flex',
                flexDirection: 'column',
                margin: '10px 0px',
                padding: '10px',
                gap: '9px',
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography>{fileInfo.name}</Typography>
                {fileInfo.progress === 100 && (
                  <img
                    src={assets.deleteIcon}
                    alt="delete icon"
                    onClick={() => handleDeleteFile(fileInfo.name, index)}
                    style={{ cursor: 'pointer' }}
                  />
                )}
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography
                  sx={{ fontSize: '12px', color: '#0B0C0C', opacity: '0.5' }}
                >{`${fileInfo.size}${parseInt(fileInfo.timeLeft) > 0 ? ` - ${fileInfo.timeLeft} Seconds left` : ''}`}</Typography>
                <Typography sx={{ fontSize: '12px', color: '#0B0C0C', opacity: '0.5' }}>
                  {`${Math.round(fileInfo.progress)}%`}
                </Typography>
              </Box>

              <LinearProgressWithLabel value={fileInfo.progress} />
            </Box>
          ))}
        </Box>
      )}

      {uploadedFilesInfo.length > 0 && !selectedExistingDataset && (
        <Box>
          <Box sx={{ padding: '10px 0px' }}>
            <Typography sx={{ fontWeight: 700 }}>
              {t('rightPanel.agent.createAgent.attachDataset.datasetNameLabel')}
            </Typography>
            <TextField
              variant="standard"
              defaultValue={uploadedFilesInfo[0].name}
              fullWidth
              placeholder={t('rightPanel.agent.createAgent.attachDataset.datasetNameLabelPlaceHolder')}
              onChange={(event) => (newDatasetName.current = event.target.value)}
              onBlur={updateDatasetDetails}
            />
          </Box>
          <Box sx={{ padding: '10px 0px' }}>
            <Typography sx={{ fontWeight: 700 }}>
              {t('rightPanel.agent.createAgent.attachDataset.datasetDescription')}
            </Typography>
            <TextField
              variant="standard"
              fullWidth
              placeholder={t('rightPanel.agent.createAgent.attachDataset.datasetNameDescriptionPlaceHolder')}
              onChange={(event) => (newDatasetDescription.current = event.target.value)}
              onBlur={updateDatasetDetails}
            />
          </Box>
        </Box>
      )}

      <Box sx={{ paddingTop: '20px' }}>
        <Box sx={{ display: 'flex' }}>
          <InputLabel sx={{ paddingBottom: '5px' }} className="mui-form-label">
            {t('rightPanel.agent.createAgent.topK')}
          </InputLabel>
          <Tooltip
            title={
              <Box component={'ul'} paddingLeft={'10px'}>
                {topKtooltipPoints.map((point) => {
                  const [tooltipTitle, tooltipDescription] = t(
                    `rightPanel.agent.createAgent.topKTooltipPoints.${point}`
                  ).split(': ');
                  return (
                    <Box component="li" key={point}>
                      <strong>{tooltipTitle} : </strong>
                      {tooltipDescription}
                    </Box>
                  );
                })}
              </Box>
            }
            arrow
          >
            <Icon sx={{ verticalAlign: 'middle', marginLeft: '5px', cursor: 'pointer' }}>
              <InfoIcon sx={{ width: '20px' }} />
            </Icon>
          </Tooltip>
        </Box>
        <NumberInput
          min={1}
          max={100}
          defaultValue={5}
          onChange={(event, newValue) => {
            attachDatasetTopK(newValue ? newValue : 256);
          }}
        />
      </Box>

      {uploadedFilesInfo.length > 0 && (
        <Box sx={{ paddingTop: '60px' }}>
          <Typography sx={{ fontWeight: 700 }}>{t('rightPanel.agent.createAgent.attachDataset.webLinks')}</Typography>
          <Typography sx={{ fontSize: '14px' }}>
            {t('rightPanel.agent.createAgent.attachDataset.webLinkHelperText')}
          </Typography>
          <TextField
            variant="standard"
            multiline
            fullWidth
            placeholder={t('rightPanel.agent.createAgent.attachDataset.webLinkPlaceHolder')}
            sx={{ padding: '10px 0px' }}
            onBlur={(event) => handleWebLinks(event.target.value)}
          />
        </Box>
      )}
    </Box>
  );
}

export default AttachNewDataset;
