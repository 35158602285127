import { useEffect, useState, useContext, useRef } from 'react';
import { ActiveNavItemContext } from '../../components/rightPanel/RightPanelContext';
import Header from './Header';
import ResponseBox from './ResponseBox';
import UserContent from './UserContent';
import ResponseContent from './ResponseContent';
import { useTheme, useMediaQuery, Box, Typography } from '@mui/material';
import '../../css/ChatInterface.css';
import QueryBox from './QueryBox';
import User from '../../helpers/User';
import Api, { AgentChatMessages, ChatMessages, SkillParameters } from '../../data/api/Api';
import { Skill } from '../../data/models/Skill';
import { LlmModel } from '../../data/models/LlmModel';
import { RightNavPanel } from '../../components/rightPanel/rightNavPanel/RightNavPanel';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useAppDispatch, useAppSelector } from '../../reduxStore/redux-hooks';
import { setSuccessResponseModal } from '../../reduxStore/slices/fileUploadSlice';
import { setInitialHistoryState } from '../../reduxStore/slices/historySlice';
import { RecentChatList } from './RecentChatList';
import FeaturedList from './FeaturedList';
import {
  chatPageNavigation,
  NoAccessToInnovationCenterHTML,
  PartyType,
  askButtonText,
} from '../../components/staticComponents/StaticHtmlGenerator';
import ConversationGuides from './ConversationGuides';
import InnovationCenter from '../../components/staticComponents/InnovationCenter';
import customAppsData from '../../components/rightPanel/subpanel/customApps/CustomApps.json';
import { showUserActionContentOnChat } from '../../reduxStore/slices/NotifyUserActionContentSlice';
import FeedbackForm from '../chat/Feedback';
import UsePageNavigation from '../../components/sharedComponents/customHooks/usePageNavigation/UsePageNavigation';
import { getAuthInstance } from '../../helpers/Auth';
import SowCommitAI from '../../components/rightPanel/subpanel/customApps/SowCommitAI';
import SowResponseTable from '../../components/rightPanel/subpanel/customApps/SowResponseTable';
import { saveSowPredictorQueryResponse, clearChat } from '../../reduxStore/slices/CustomAppsSlice';
import QETPlatform from '../../components/rightPanel/subpanel/customApps/QETPlatfrom';
import { CustomAppName } from '../../components/staticComponents/StaticHtmlGenerator';
import { MyAgentsData } from '../../components/rightPanel/subpanel/agents/myAgents/MyAgents';
import { UseTrackedNavigate } from '../../components/sharedComponents/customHooks/useTrackedNavigate/UseTrackedNavigate';
import { LogButtonEventToGA } from '../../components/sharedComponents/googleAnalytics/GoogleAnalytics';

export function Chat() {
  const dispatch = useAppDispatch();
  const conversationIdRef = useRef('');
  const conversationMessageCountRef = useRef(0);
  const { activeNavItem, setActiveNavItem } = useContext(ActiveNavItemContext);
  const { t } = useTranslation();
  const subPanelOpen =
    activeNavItem !== null && activeNavItem !== t('welcomepage.Home') && activeNavItem !== t('welcomepage.Chat');
  const [user, setUser] = useState(User.empty);
  const [chatContents, setChatContents] = useState<JSX.Element[]>([]);
  const [messages, setMessages] = useState<ChatMessages[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [appMessage, setAppMessage] = useState('');
  const [isStreamComplete, setisStreamComplete] = useState(false);
  let currmessages: ChatMessages[] = [];
  const { enqueueSnackbar } = useSnackbar();
  const { userActionContent } = useAppSelector((state) => state.notifyUserActionContent);
  const { activeAgentId, activeAgentModelName } = useAppSelector((state) => state.agents.activeAgent);
  const { activeDataSetId } = useAppSelector((state) => state.dataset.selectedDataSet);
  const { fileUpload } = useAppSelector((state) => state.fileUpload);
  const { historyResponse, conversationID, conversationMessageCounter, selectedModelHistory, agentIdentifier } =
    useAppSelector((state) => state.history.historyState);
  const { innovationCenter, customAppDatasetId } = useAppSelector((state) => state.featured);
  const temperature = 0.3;
  const [selectedGuide, setSelectedGuide] = useState('');
  const navigate = UseTrackedNavigate();
  const { isWelcomePage, isChatPage } = UsePageNavigation();
  const shouldClearChat = useAppSelector((state) => state.customApps.shouldClearChat);
  const { choosenCustomApp, sowCommitAiQueryResponse, sowPredictorQueryResponse } = useAppSelector(
    (state) => state.customApps
  );
  const onGuideClick = (customText: string) => {
    setSelectedGuide(customText);
  };
  const [selectedModel, setSelectedModel] = useState(LlmModel.defaultModal);
  const [llmModels, setLlmModels] = useState<LlmModel[]>([LlmModel.defaultModal]);

  useEffect(() => {
    const modelFound = llmModels.find((model) => model.id === selectedModelHistory);
    setSelectedModel(modelFound ? modelFound : LlmModel.defaultModal);
  }, [selectedModelHistory]);

  let modelSelection: LlmModel | undefined;

  const loadChatHistory = async () => {
    // Caution : As of now, we are differentiating between an Agents history and normal chat history based on PARTY_ID attrbute which is present in the former and not in the later.
    // Later if in case this party_id has to be added to the normal conversation history then this might break the loading of agents History data.

    if (historyResponse) {
      if (conversationIdRef.current && conversationIdRef.current === conversationID) {
        return; //Doing a early return if user clicked on same history
      }
      if (conversationIdRef.current && conversationIdRef.current !== conversationID) {
        setChatContents([]);
        setMessages([]);
      }
      const agentsList = (await Api.getMyAgentsList()).data as MyAgentsData[];
      historyResponse.map((message) => {
        if (message.name.includes(`${user.initials()}`)) {
          const userContent = (
            <UserContent
              initials={user.initials()}
              message={message.content}
              imageSelectionChange={() => 'imagePath'}
              toolTipTextChange={(value) => `Tooltip for ${value}`}
            />
          );
          handleAddChatContent(userContent);
        } else {
          // if agent then fetch the agent's model and show its tooltip
          const modelData =
            agentIdentifier === PartyType.Agent
              ? agentsList.find((agent) => agent.name === message.name)
              : { model_name: message.name.split(':')[0] };
          modelSelection = modelData && llmModels.find((model) => model.id === modelData.model_name);
          if (modelSelection) {
            const initialResponseContent = (
              <ResponseContent
                modelIcon={modelSelection.image}
                modelTooltip={modelSelection.tooltip}
                appMessage={message.content}
                isLoading={false}
                isStreamComplete={true}
              />
            );
            handleAddChatContent(initialResponseContent);
          }
        }
      });
      conversationIdRef.current = conversationID;
      conversationMessageCountRef.current = conversationMessageCounter;
    }
  };

  useEffect(() => {
    loadChatHistory();
  }, [historyResponse]);

  useEffect(() => {
    if (userActionContent.length) {
      const initialResponseContent = (
        <ResponseContent
          appMessage={userActionContent}
          modelIcon={selectedModel.image}
          modelTooltip={selectedModel.tooltip}
          isLoading={false}
          isStreamComplete={isStreamComplete}
        />
      );
      handleAddChatContent(initialResponseContent);
      dispatch(showUserActionContentOnChat(''));
    }
    if (innovationCenter.isActive && isWelcomePage) {
      // This line checks if the user is currently on the 'apps' tab,has access to the Innovation Center
      //and present on welcome page at that moment.
      navigate(chatPageNavigation);
      setChatContents([]);
      innovationCenter.userHasAccess
        ? handleAddChatContent(<InnovationCenter />)
        : handleAddChatContent(NoAccessToInnovationCenterHTML());
    }

    //clear the previous messages while switching between different custom apps and set system propmt message.
    if (innovationCenter.isActive && customAppDatasetId) {
      const selectedCustomApp = customAppsData.custom_apps.find(
        (customApp) => customApp.dataset_id === customAppDatasetId
      );
      if (selectedCustomApp) {
        setMessages([{ content: selectedCustomApp.system_prompt, role: 'system' }]);
      }
    }
  }, [userActionContent, activeNavItem, customAppDatasetId]);

  //clearing the chat on change of the custom app
  useEffect(() => {
    if (shouldClearChat) {
      if (choosenCustomApp.selectedCustomApp) {
        setChatContents([]);
        setMessages([]);
      }
      dispatch(clearChat(false));
    }
  }, [shouldClearChat]);

  useEffect(() => {
    handleWelcomeMessageCustomApps();
  }, [choosenCustomApp, saveSowPredictorQueryResponse, sowCommitAiQueryResponse, sowPredictorQueryResponse]);

  const handleWelcomeMessageCustomApps = () => {
    if (
      choosenCustomApp.isCustomAppSelected &&
      !sowCommitAiQueryResponse.length &&
      !Object.values(sowPredictorQueryResponse).length
    ) {
      const initialResponseContent = (
        <ResponseContent
          appMessage={choosenCustomApp.welcomeMessage}
          isLoading={false}
          modelIcon={selectedModel.image}
          modelTooltip={selectedModel.tooltip}
          isStreamComplete={isStreamComplete}
        />
      );
      handleAddChatContent(
        choosenCustomApp.selectedCustomApp === CustomAppName.QETPlatform ? <QETPlatform /> : initialResponseContent
      );
      //For SowCommit there are Guildlines to show along with welcome message, adding content again
      if (choosenCustomApp.selectedCustomApp === CustomAppName.SOWCommitAI) {
        handleAddChatContent(<SowCommitAI />);
      }
    }
    if (
      choosenCustomApp.isCustomAppSelected &&
      (sowCommitAiQueryResponse.length || Object.values(sowPredictorQueryResponse).length)
    ) {
      const sowResponseContent = (
        <SowResponseTable
          sowQueryResponse={
            choosenCustomApp.selectedCustomAppSkill === 'sow_commit_ai' ||
            choosenCustomApp.selectedCustomAppSkill === 'sow_commit_acc_ai'
              ? sowCommitAiQueryResponse
              : sowPredictorQueryResponse
          }
          selectedCustomAppSkills={choosenCustomApp.selectedCustomAppSkill}
        />
      );
      handleUpdateLastChatContentOfCustomApp(sowResponseContent);
    }
  };
  useEffect(() => {
    // Fetch data and update user on component mount
    const fetchData = async () => {
      (await getAuthInstance()).login().catch((error) => {
        enqueueSnackbar(error);
      });

      // Update the user
      const user = await User.getInstance();
      setUser(user);
      await Api.listLlmModels().then((response) => {
        setLlmModels(response);
      });
    };

    if (isWelcomePage) {
      setActiveNavItem(t('welcomepage.Home'));
    } else if (isChatPage) {
      setActiveNavItem(t('welcomepage.Chat'));
    }

    fetchData();
  }, []);

  const updateMessages = (newMessages: ChatMessages[]) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      { content: newMessages[0].content, role: newMessages[0].role, ...(activeAgentId && { name: user.initials() }) },
    ]);
    currmessages.push(...newMessages);
  };

  const clearChatHistory = () => {
    setMessages([]);
    setChatContents([]);
    conversationIdRef.current = '';
    conversationMessageCountRef.current = 0;
    dispatch(setSuccessResponseModal(false));
    dispatch(
      setInitialHistoryState({
        conversationID: '',
      })
    );
  };

  const handleAddChatContent = (content: JSX.Element) => {
    setChatContents((prevContents) => [...prevContents, content]);
  };

  const handleUpdateLastChatContent = (content: JSX.Element) => {
    setChatContents((prevContents) => {
      const newContents = [...prevContents];
      newContents[newContents.length - 1] = content;
      return newContents;
    });
  };

  const handleUpdateLastChatContentOfCustomApp = (content: JSX.Element) => {
    setChatContents((prevContents) => {
      const newContents = [...prevContents];
      const lastContent = newContents[newContents.length - 1];
      const lastContentText = lastContent.props.appMessage;
      //When a new response generates then the old response to be replaced with new response in custom apps && // When SOW Commit is selected, validating to show the guidelines with the response
      if (
        (lastContentText !== choosenCustomApp.welcomeMessage &&
          choosenCustomApp.selectedCustomAppSkill !== 'sow_commit_ai') ||
        (choosenCustomApp.selectedCustomAppSkill === 'sow_commit_ai' && newContents.length > 2)
      ) {
        newContents[newContents.length - 1] = content;
      } else {
        newContents[newContents.length] = content;
      }
      return newContents;
    });
  };
  // Function to truncate a string to a specified number of words.
  const truncateString = (inputString: string, wordCount: number): string => {
    // Split the input string into an array of words.
    const words = inputString.split(' ');

    // Check if the number of words in the input string exceeds the specified word count.
    if (words.length > wordCount) {
      // If so, slice the array to contain only up to the specified number of words,
      // join these words back into a string, and append an ellipsis ('...') to indicate truncation.
      return words.slice(0, wordCount).join(' ') + '...';
    }
    // If the input string contains wordCount words or fewer, return it unchanged.
    return inputString;
  };

  //this hanldes whether to use default query api or ai assets api.
  const handlePostQueryApi = (model: LlmModel) => {
    const skill_parameters: SkillParameters = {
      temperature: temperature,
    };

    //if user changes llm model after activating agent, then pass chosen model under skill parameter object;
    if (activeAgentId && activeAgentModelName.toLowerCase() !== model.name.toLowerCase()) {
      skill_parameters.model_name = model.id as SkillParameters['model_name'];
    }

    return activeAgentId
      ? Api.postAgentQuery(
          messages as AgentChatMessages[],
          activeAgentId,
          'Agent',
          skill_parameters,
          conversationIdRef.current
        )
      : Api.postQuery(activeDataSetId, Skill.completion, messages, model, skill_parameters, customAppDatasetId);
  };

  let newContent = '';
  const checkRegex = new RegExp('<details[^>]*>((.|s)*?)</details>');

  const handlePostQuery = (newQuery: string, model: LlmModel) => {
    LogButtonEventToGA(askButtonText);
    //do not create new conversation Id for agent chat as Agent will have
    //seperate conversation Id for storing agent session.
    if (conversationIdRef.current === '' && !activeAgentId) {
      let message = newQuery;
      Api.addTitles(newQuery).then((response) => {
        conversationIdRef.current = response.data?.conversation_id as string;
        dispatch(
          setInitialHistoryState({
            conversationID: conversationIdRef.current,
          })
        );
        // Split the message string into an array of words, then filter out any falsy values (like empty strings).
        // This is done by splitting the message on spaces, and the .filter(Boolean) part effectively removes
        // any empty strings that might result from multiple consecutive spaces in the original message.
        // After filtering, check if the length (word count) is greater than 10.
        if (message.split(/\s+/).filter(Boolean).length > 10) {
          // If the message contains more than 10 words, truncate it to 5 words.
          // This uses the previously defined truncateString function.
          message = truncateString(message, 5);
        }
        dispatch(
          setInitialHistoryState({
            activeHistory: message,
          })
        );
      });
    }
    currmessages = messages;
    updateMessages([{ content: newQuery, role: 'user', ...(activeAgentId && { name: user.initials() }) }]);
    setIsLoading(true);
    setAppMessage('');
    setisStreamComplete(false);
    const userContent = (
      <UserContent
        initials={user.initials()}
        message={newQuery}
        imageSelectionChange={() => 'imagePath'}
        toolTipTextChange={(value) => `Tooltip for ${value}`}
      />
    );

    handleAddChatContent(userContent);
    const initialResponseContent = (
      <ResponseContent
        appMessage=""
        modelIcon={selectedModel.image}
        modelTooltip={selectedModel.tooltip}
        isLoading={true}
        isStreamComplete={isStreamComplete}
      />
    );

    handleAddChatContent(initialResponseContent);

    handlePostQueryApi(model)
      .then(async (response) => {
        // TODO: Add error handling
        const body = response.response.body;
        if (response.response.status !== 200) {
          if (response.error?.message) {
            enqueueSnackbar(response.error.message);
          }
        }
        if (!body) {
          setIsLoading(false);
          return;
        }
        // Check if the current conversation ID is not empty.
        if (conversationIdRef.current !== '' && !activeAgentId) {
          // Increment the conversation message count by 1.
          // This counter keeps track of the number of messages or queries sent during this conversation.
          conversationMessageCountRef.current = conversationMessageCountRef.current + 1;

          // Determine the skill name based on whether any files have been uploaded.
          // If there are files, use 'docCompletion' skill; otherwise, use 'completion' skill.
          // This likely affects how the conversation or query is processed on the backend.
          const skill_name = fileUpload.uploadedFiles.length > 0 ? Skill.docCompletion : Skill.completion;

          // Construct a dataset identifier using the user's initials and the determined skill name.
          // This identifier might be used for logging, analytics, or to tailor the conversation processing.
          const modelAPPDataset = `${user.initials()}:${skill_name}`;

          // Call the Api's updateConversation method to update the current conversation.
          // This method likely sends the new query along with the updated message count and dataset identifier
          // to the server, where it may be processed or logged accordingly.
          Api.updateConversation(
            conversationIdRef.current,
            newQuery,
            conversationMessageCountRef.current,
            modelAPPDataset
          );
        }

        try {
          const readableStream = body.pipeThrough(new TextDecoderStream());
          const reader = readableStream.getReader();
          setisStreamComplete(false);

          if (!reader) {
            enqueueSnackbar(t('snackbar.alertMessage.readerNotFound'));
            setIsLoading(false);
            return;
          }

          while (true) {
            const { value, done } = await reader.read();
            if (done) {
              setisStreamComplete(true);
              break;
            }
            if (value === undefined) {
              continue;
            }

            const strings = value.split('\n');

            strings.forEach((string) => {
              if (string === '') {
                return;
              }
              const data = JSON.parse(string);
              if (!data.data.content) return;

              newContent += data.data.content;
              //for generating links on chat
              if (data.data.links) {
                newContent += data.data.links;
              }
              //use this agent conversationId for storing the agent session.
              if (data.data.conversation_id && activeAgentId) {
                conversationIdRef.current = data.data.conversation_id;
              }
              setIsLoading(false);
              setAppMessage(newContent);
            });
          }
        } catch (error) {
          enqueueSnackbar(t('snackbar.alertMessage.queryErrorHandling'), { variant: 'error' });
          console.error(error);
          setIsLoading(false);
        }

        updateMessages([
          {
            content: newContent.replace(checkRegex, '').replaceAll('<br>', '\n'),
            role: 'assistant',
            ...(activeAgentId && { name: user.initials() }),
          },
        ]);
        // Check if there's an ongoing conversation by checking if a conversation ID exists.
        if (conversationIdRef.current && !activeAgentId) {
          // Increase the count of messages sent in this conversation by one.
          conversationMessageCountRef.current += 1;

          // Decide which skill to use based on whether any files have been uploaded.
          // If files are present, use the 'docCompletion' skill for document processing.
          // If no files are uploaded, use the 'completion' skill for standard queries.
          const skill_name = fileUpload.uploadedFiles.length > 0 ? Skill.docCompletion : Skill.completion;

          //This identifier ('modelAPPDataset') is crucial for storing
          // responses in a way that allows us to differentiate between various AI models and their
          // versions when reloading history
          //Currently, it's set to 'gpt-4' but this will be dynamic in the future
          const modelAPPDataset = selectedModelHistory + ':' + skill_name + ':' + activeDataSetId;

          // Call the Api's updateConversation method to update the current query response .
          Api.updateConversation(
            conversationIdRef.current,
            newContent,
            conversationMessageCountRef.current,
            modelAPPDataset
          );
        }
      })
      .catch(() => {
        setIsLoading(false);
        enqueueSnackbar(t('snackbar.alertMessage.internetDisconnected'));
      })
      .finally(() => {
        dispatch(
          setInitialHistoryState({
            clearValue: false,
          })
        );
      });
  };

  useEffect(() => {
    if (!isLoading) {
      const responseContent = (
        <ResponseContent
          appMessage={appMessage}
          isLoading={false}
          modelIcon={selectedModel.image}
          modelTooltip={selectedModel.tooltip}
          isStreamComplete={isStreamComplete}
        />
      );
      handleUpdateLastChatContent(responseContent);
    }
  }, [appMessage, isLoading, isStreamComplete]);

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <>
      {/* load these initial components only after the user is authenticated and loaded fully. */}
      {user.fullName && (
        <div id="boundary-box">
          <Header clearChatHistory={clearChatHistory} />
          {isWelcomePage && (
            <Typography
              variant="h3"
              sx={{ marginBottom: '20px', color: '#793196', textAlign: 'center', fontWeight: 700 }}
            >
              {t('chatInterface.welcomeMessage', { name: user.firstName() })}
            </Typography>
          )}

          <Box
            sx={{
              marginRight: isLargeScreen ? (subPanelOpen ? '26.5rem' : '100px') : '100px',
              transition: 'all 200ms ease-in-out',
            }}
          >
            {isWelcomePage && <FeaturedList />}

            {isChatPage && (
              <ResponseBox
                userFirstName={user.firstName()}
                dynamicContent={chatContents}
                modelIcon={selectedModel.image}
                modelTooltip={selectedModel.tooltip}
              />
            )}
            <FeedbackForm />
            <QueryBox
              userInitials={user.initials()}
              postQuery={handlePostQuery}
              clearChatHistory={clearChatHistory}
              selectedGuide={selectedGuide}
            />
            {isWelcomePage && <ConversationGuides onGuideClick={onGuideClick} />}

            {isWelcomePage && <RecentChatList />}
          </Box>
          <RightNavPanel userFullName={user.fullName} initials={user.initials()} />
        </div>
      )}
    </>
  );
}
