import React, { useContext } from 'react';
import Logo from '../../components/Logo';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { useAppDispatch } from '../../reduxStore/redux-hooks';
import { saveInnovationCenterDetails } from '../../reduxStore/slices/FeaturedSlice';
import { ActiveNavItemContext } from '../../components/rightPanel/RightPanelContext';
import { useTranslation } from 'react-i18next';
import { ExternalId } from '../authConfig';
import { welcomePageNavigation } from '../../components/staticComponents/StaticHtmlGenerator';

interface HeaderProps {
  clearChatHistory: () => void;
}

const Header: React.FC<HeaderProps> = ({ clearChatHistory }) => {
  const externalId = ExternalId.tenantName;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { setActiveNavItem } = useContext(ActiveNavItemContext);

  const handleWelcomePageRoutes = () => {
    clearChatHistory();
    dispatch(saveInnovationCenterDetails({ isActive: false }));
    setActiveNavItem(t('welcomepage.Home'));
  };

  return (
    <Box component="section" sx={{ display: 'flex', paddingBottom: '1.5rem' }}>
      <Link to={welcomePageNavigation} onClick={handleWelcomePageRoutes}>
        <Logo externalId={externalId} />
      </Link>
    </Box>
  );
};

export default Header;
