import React, { useContext, useEffect, useRef, useState } from 'react';
import { ActiveNavItemContext } from '../RightPanelContext';
import { Box, Typography, Slide } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CreateAgent from './agents/createAgent/CreateAgent';
import { SubPanelClasses } from './SubPanelEnums';
import History from './history/History';
import Agents from './agents/Agents';
import UserProfile from './userProfile/UserProfile';
import CloseRightNavPanel from '../rightNavPanel/closeRightNavPanel/CloseRightNavPanel';
import {
  NAV_PANEL_WIDTH,
  SUB_PANEL_WIDTH,
  SUB_PANEL_WIDTH_SX,
  usePortrait,
} from '../../staticComponents/StaticHtmlGenerator';
import Dataset from './dataset/Dataset';
import CustomApps from './customApps/CustomApps';
import { useAppSelector } from '../../../reduxStore/redux-hooks';

export function SubPanel({ userFullName }: { userFullName: string }) {
  const { activeNavItem, setActiveNavItem } = useContext(ActiveNavItemContext);
  const { t } = useTranslation();
  const subPanelOpen =
    activeNavItem !== null && activeNavItem !== t('welcomepage.Home') && activeNavItem !== t('welcomepage.Chat');
  const [isCreateNewPage, setIsCreateNewPage] = useState<boolean>(false);
  const isCreateNewPageShownRef = useRef<boolean>(false);

  const subPanelOpenReference = useRef<HTMLDivElement>(null);

  // commented below code until we have good solution to close right panle on click on anywhere outside it.
  // const handleOutsideClickToClosePanel = (event: MouseEvent) => {
  //   if (
  //     subPanelOpenReference.current &&
  //     !subPanelOpenReference.current.contains(event.target as Node) &&
  //     !innovationCenter.isActive &&
  //     !isCreateNewPageShownRef.current
  //   ) {
  //     setActiveNavItem(null);
  //   }
  // };

  // useEffect(() => {
  //   if (subPanelOpen) {
  //     document.addEventListener('mousedown', handleOutsideClickToClosePanel);
  //     if (document.activeElement instanceof HTMLElement) {
  //       document.activeElement.blur();
  //     }
  //     return () => {
  //       document.removeEventListener('mousedown', handleOutsideClickToClosePanel);
  //     };
  //   }
  // }, [subPanelOpen]);

  useEffect(() => {
    setIsCreateNewPage(false);
    isCreateNewPageShownRef.current = false;
  }, [activeNavItem]);

  const closeSubPanel = () => {
    setActiveNavItem(null);
  };
  const portrait = usePortrait();

  interface Translation {
    title: string;
    newTitle: string;
    component: React.ComponentType<object> | null;
  }

  const mapRightPanelcomponents: { [key: string]: Translation } = {
    agent: {
      title: 'rightPanel.agents',
      newTitle: 'rightPanel.agent.newAgent',
      component: Agents,
    },
    dataset: {
      title: 'rightPanel.datasets',
      newTitle: 'rightPanel.newDatasets',
      component: Dataset,
    },
    history: {
      title: 'history.history',
      newTitle: 'history.clearHistory',
      component: History,
    },
    teams: {
      title: 'rightPanel.teams',
      newTitle: 'rightPanel.newTeams',
      component: null,
    },
    apps: {
      title: 'rightPanel.apps',
      newTitle: '',
      component: CustomApps,
    },
  };

  //This will add dynamic classes to adjust the width of subpanel.
  const getSubPanelClasses = () => {
    return activeNavItem === t('welcomepage.Agent') && isCreateNewPage ? SubPanelClasses.CreateAgentSubPanel : '';
  };

  const closeButton = CloseRightNavPanel(closeSubPanel);

  const loadRightPanelComponent = () => {
    if (activeNavItem !== null) {
      if (activeNavItem in mapRightPanelcomponents) {
        const SelectedComponent = mapRightPanelcomponents[activeNavItem].component;

        return (
          <Box>
            {SelectedComponent ? (
              <SelectedComponent />
            ) : (
              <>
                <UserProfile userFullName={userFullName} />
                <Typography>&quot;{activeNavItem}&quot; to be implemented.</Typography>
              </>
            )}
          </Box>
        );
      }
    }
  };

  const createAIAssets = () => {
    setIsCreateNewPage(true);
    isCreateNewPageShownRef.current = true;
  };

  const getTitleWithTranslation = () => {
    return t(mapRightPanelcomponents[activeNavItem!].title);
  };

  const getNewAIAssetTitleWithTranslation = () => {
    return t(mapRightPanelcomponents[activeNavItem!].newTitle);
  };

  return (
    <Box id={'sub-panel'} ref={subPanelOpenReference}>
      <Slide direction="left" in={subPanelOpen} mountOnEnter unmountOnExit onExited={closeSubPanel}>
        <Box
          className={getSubPanelClasses()}
          sx={{
            position: 'absolute',
            top: {
              xs: portrait ? 64 : 0,
              md: 0,
            },
            right: NAV_PANEL_WIDTH,
            width: {
              xs: portrait ? SUB_PANEL_WIDTH_SX : SUB_PANEL_WIDTH,
              md: SUB_PANEL_WIDTH,
            },
            height: '100%',
            border: '1px #F2F2F2 solid inset',
            backgroundColor: '#FBFAFA',
            boxShadow: '0px 4px 33px 0px #00000012 inset',
          }}
        >
          {!isCreateNewPage && (
            <Box sx={{ padding: '17px 20px 30px', backgroundColor: '#FBFAFA' }}>
              <Box
                display={'flex'}
                flexDirection={'row'}
                sx={{
                  justifyContent: 'space-between',
                  paddingBottom: '20px',
                }}
              >
                {activeNavItem && (
                  <>
                    <Typography textAlign={'left'} fontSize={'20px'} fontWeight={700} lineHeight={'28px'}>
                      {getTitleWithTranslation()}
                    </Typography>
                    <Typography
                      sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                      textAlign={'right'}
                      fontSize={'16px'}
                      fontWeight={400}
                      lineHeight={'24px'}
                      color={'#256AFF'}
                      onClick={() => createAIAssets()}
                    >
                      {getNewAIAssetTitleWithTranslation()}
                    </Typography>
                  </>
                )}
              </Box>
              {loadRightPanelComponent()}
            </Box>
          )}
          {isCreateNewPage && activeNavItem === t('welcomepage.Agent') && <CreateAgent />}
          {/* Since we dont show close icon for new AI asset details. */}
          {isCreateNewPage ? null : closeButton}
        </Box>
      </Slide>
    </Box>
  );
}
