import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const PageNotFound = () => {
  const { t } = useTranslation();

  return (
    <Box display={'flex'} justifyContent={'center'} textAlign={'center'} height={'100vh'} alignItems={'center'}>
      <Box textAlign={'center'}>
        <Typography variant="h3" gutterBottom>
          {t('pageNotFound.errorCode')}
        </Typography>
        <Typography variant="h6" gutterBottom>
          {t('pageNotFound.errorMessage')}
        </Typography>
      </Box>
    </Box>
  );
};

export default PageNotFound;
