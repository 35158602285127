import { Box, Typography } from '@mui/material';
import React from 'react';
import * as assets from '../../../../assets';
import { useTranslation } from 'react-i18next';

function SowCommitAI() {
  const { t } = useTranslation();

  return (
    <>
      <Box className="msgBox">
        <Box className="welcome-icon">
          <img id="changeIcon" src={assets.ChatGptIcon} alt="chat gpt logo" />
        </Box>

        <Box className="chat-content" sx={{ '& p': { marginTop: 0 } }}>
          <Box fontWeight={700}>
            <Typography fontWeight={700} sx={{ gap: '3rem' }}>
              {t('sowCommitAI.sowCommitAIGuidelines')}
            </Typography>
          </Box>
          <Box paddingTop={'0.5rem'} fontWeight={700}>
            {t('sowCommitAI.sowCommitAIUserGuidelines')}
          </Box>
          <Box paddingTop={'0.5rem'}>{t('sowCommitAI.sowCommitAIUserGuidelinesDescription')}</Box>
          <Box paddingTop={'0.5rem'} fontWeight={700}>
            {t('sowCommitAI.sowCommitAIUserGuidelinesNote')}
          </Box>
          <ul>
            {(t('sowCommitAI.sowCommitAIUserGuidelinesNoteList', { returnObjects: true }) as string[]).map(
              (note, index) => (
                <li key={index}>{note}</li>
              )
            )}
          </ul>
        </Box>
      </Box>
    </>
  );
}

export default SowCommitAI;
