import { Box, Card, CardContent, Typography } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { useContext, useEffect, useState } from 'react';
import Api from '../../data/api/Api';
import { Link } from 'react-router-dom';
import { useAppDispatch } from '../../reduxStore/redux-hooks';
import { setInitialHistoryState } from '../../reduxStore/slices/historySlice';
import { selectedDataSet } from '../../reduxStore/slices/DataSetSlice';
import { ActiveNavItemContext } from '../../components/rightPanel/RightPanelContext';
import * as assets from '../../assets/';
import { AppSkillId } from '../../components/staticComponents/StaticHtmlGenerator';
import { getFormattedDateAndTime } from '../../components/staticComponents/staticUtiles';
import { useTranslation } from 'react-i18next';
import {
  ConversationData,
  ConversationResponse,
  Dataset,
  HistoryResponse,
} from '../../components/rightPanel/subpanel/history/History';
import { PartyType, chatPageNavigation } from '../../components/staticComponents/StaticHtmlGenerator';
import { UseTrackedNavigate } from '../../components/sharedComponents/customHooks/useTrackedNavigate/UseTrackedNavigate';

export function RecentChatList() {
  const { setActiveNavItem } = useContext(ActiveNavItemContext);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = UseTrackedNavigate();
  const [historyTitles, setHistoryTitles] = useState<ConversationData[]>([]);

  useEffect(() => {
    const getTitles = async () => {
      const response = (await Api.fetchTitles(0, 5, PartyType.Unknown)) as ConversationResponse;
      setHistoryTitles(response.data);
    };
    getTitles();
  }, []);

  const fetchAndProcessConversationMessages = async (conversationId: string, conversationHeading: string) => {
    navigate(chatPageNavigation);
    setActiveNavItem('history');
    const conversationMessagesResponse = (await Api.fetchMessages(conversationId)) as HistoryResponse;
    const modelDatasetIDSlice = conversationMessagesResponse.data[conversationMessagesResponse.data.length - 1].name;
    dispatch(
      setInitialHistoryState({
        historyResponse: conversationMessagesResponse.data,
        activeHistory: conversationHeading,
        clearValue: false,
        conversationID: conversationId,
        conversationMessageCounter: conversationMessagesResponse.data.length,
      })
    );
    setActiveModelAndDatasetFromMessage(modelDatasetIDSlice);
  };

  const setActiveModelAndDatasetFromMessage = async (modelDatasetIDSlice: string) => {
    const messageComponents = modelDatasetIDSlice.split(':');
    const modelFromResponse = messageComponents[0];
    const activeDatasetId = messageComponents[2];
    dispatch(
      setInitialHistoryState({
        selectedModelHistory: modelFromResponse,
      })
    );
    if (activeDatasetId !== '') {
      const listDatasetsResponse = await Api.listDatasets(true, true, AppSkillId.DocCompletion);
      const fetchedDatasets = listDatasetsResponse.data as Dataset[];
      const activeDataset = fetchedDatasets.find((dataset) => dataset._id === activeDatasetId);
      if (activeDataset) {
        dispatch(
          selectedDataSet({
            activeDataSetId: activeDatasetId,
            activeDataSetName: activeDataset.name as string,
          })
        );
      }
    }
  };
  return (
    <Box sx={{ marginTop: '40px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography sx={{ marginBottom: '10px' }}>
            <img src={assets.recentChat} /> Your recent chats
          </Typography>
          <KeyboardArrowUpIcon />
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Link to={chatPageNavigation} style={{ textDecoration: 'none' }} onClick={() => setActiveNavItem('history')}>
            {t('welcomepage.viewAll')}
          </Link>
          <ArrowRightAltIcon />
        </Box>
      </Box>

      <Box sx={{ display: 'flex', gap: 2, width: '100%', justifyContent: 'left', marginBottom: '70px' }}>
        {historyTitles.map((listItem, index) => {
          const { timeString, dateString } = getFormattedDateAndTime(listItem.last_updated);
          return (
            <Card
              key={index}
              sx={{
                border: '1px solid #E5E5E5',
                borderRadius: '8px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                cursor: 'pointer',
                overflow: 'visible',
                width: '20%',
              }}
            >
              <CardContent>
                <Typography
                  onClick={() => fetchAndProcessConversationMessages(listItem.conversation_id, listItem.title)}
                  sx={{
                    whiteSpace: 'nowrap', // Prevent text from wrapping
                    overflow: 'hidden', // Hide overflowing text
                    textOverflow: 'ellipsis', // Show ellipsis for overflowing text
                    fontSize: '13px',
                  }}
                >
                  {listItem.title}
                </Typography>
              </CardContent>
              <Box
                sx={{
                  padding: '16px',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Typography variant="body2" color="text.secondary" fontSize={13}>
                  {timeString}
                </Typography>
                <Typography variant="body2" color="text.secondary" fontSize={13}>
                  {dateString}
                </Typography>
              </Box>
            </Card>
          );
        })}
      </Box>
    </Box>
  );
}
