import { Button } from '@mui/material';
import React, { useState } from 'react';

const COPY_DELAY = 2000;
interface CopyToClipboardProps {
  textToCopy: string;
  initialButtonText?: string;
  copiedButtonText?: string;
}
const CopyToClipboard: React.FC<CopyToClipboardProps> = ({
  textToCopy,
  initialButtonText = 'Copy',
  copiedButtonText = 'Copied!',
}) => {
  const [copyButtonText, setCopyButtonText] = useState(initialButtonText);
  const copyResponseText = async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      setCopyButtonText(copiedButtonText);
    } catch (error) {
      console.log(error);
    } finally {
      setTimeout(() => {
        setCopyButtonText(initialButtonText);
      }, COPY_DELAY);
    }
  };
  return (
    <Button
      size="small"
      variant="outlined"
      onClick={copyResponseText}
      id="copy-button"
      sx={{ marginTop: 1, color: '#793196', borderColor: '#793196' }}
    >
      {copyButtonText}
    </Button>
  );
};
export default CopyToClipboard;
