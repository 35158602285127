import { Avatar, Box, Chip } from '@mui/material';
import * as assets from '../../assets';
import { useTranslation } from 'react-i18next';

interface ConversationGuidesProps {
  onGuideClick: (guideCustomText: string) => void;
}

const ConversationGuides = ({ onGuideClick }: ConversationGuidesProps) => {
  const { t } = useTranslation();

  const guides = [
    {
      guideName: t('welcomepage.Summarise'),
      guideCustomText: t('welcomepage.Summarisethedocument'),
      disabled: false,
    },
    {
      guideName: t('welcomepage.CodeWrite'),
      guideCustomText: t('welcomepage.Helpmedebugthiscode'),
      disabled: false,
    },
    {
      guideName: t('welcomepage.Brainstorm'),
      guideCustomText: t('welcomepage.Brainstormideas'),
      disabled: false,
    },
    {
      guideName: t('welcomepage.AnalyseData'),
      guideCustomText: t('welcomepage.Helpmetoanalysethedata'),
      disabled: true,
    },
  ];

  return (
    <>
      <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center' }}>
        {guides.map((guide, index) => {
          return (
            <Chip
              key={index}
              sx={{
                padding: '5px 15px',
                pointerEvents: guide.disabled ? 'none' : 'auto',
                cursor: guide.disabled ? 'not-allowed' : 'pointer',
                opacity: guide.disabled ? 0.5 : 1,
              }}
              label={guide.guideName}
              onClick={() => onGuideClick(guide.guideCustomText)}
              variant="outlined"
            />
          );
        })}
      </Box>
    </>
  );
};

export default ConversationGuides;
