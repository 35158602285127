import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface HistoryData {
  content: string;
  conversed_time: number;
  media: string;
  message_index: number;
  name: string;
  role: string;
}
interface InitialHistoryState {
  activeHistory: string;
  historyResponse: HistoryData[];
  clearValue: boolean;
  conversationID: string;
  conversationMessageCounter: number;
  selectedModelHistory: string;
  selectedActiveDatasetID: string;
  agentIdentifier: string;
}
interface historyState {
  historyState: InitialHistoryState;
  refreshTitleFlag: boolean;
}

const initialState: historyState = {
  historyState: {
    activeHistory: '',
    historyResponse: [],
    clearValue: true,
    conversationID: '',
    conversationMessageCounter: 0,
    selectedModelHistory: 'gpt-35-turbo-16k',
    selectedActiveDatasetID: '',
    agentIdentifier: '',
  },
  refreshTitleFlag: false,
};

const historySlice = createSlice({
  name: 'history',
  initialState,
  reducers: {
    setInitialHistoryState: (state, action: PayloadAction<Partial<InitialHistoryState>>) => {
      state.historyState = { ...state.historyState, ...action.payload };
    },
    setRefreshTitleFlag: (state) => {
      state.refreshTitleFlag = !state.refreshTitleFlag;
    },
  },
});

export const { setInitialHistoryState, setRefreshTitleFlag } = historySlice.actions;

export default historySlice.reducer;
