import Api from '../../data/api/Api';
import { ActiveNavItemContext } from '../../components/rightPanel/RightPanelContext';
import { Box, Button, Card, CardContent, Chip, Tab, Typography } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import customAppsData from '../../components/rightPanel/subpanel/customApps/CustomApps.json';
import { useAppDispatch } from '../../reduxStore/redux-hooks';
import { saveInnovationCenterDetails, saveListOfCustomAppsUserHasAccess } from '../../reduxStore/slices/FeaturedSlice';
import { ListOfExistingDataset } from '../../components/rightPanel/subpanel/dataset/DatasetModal';
import { featuredList, subFeaturedList } from '../../components/staticComponents/StaticHtmlGenerator';
import { AppSkillId } from '../../components/staticComponents/StaticHtmlGenerator';
import { TabContext, TabList } from '@mui/lab';
import { useTranslation } from 'react-i18next';

interface SubFeature {
  name: string;
  description?: string;
  icon: string;
  enabled: boolean;
}

interface SubFeatureTab {
  label: string;
  content: SubFeature[];
}

interface FeaturesType {
  featureId: string;
  featureName: string;
  featureBackgroundColor: string;
  featureDisable: boolean;
  featureIcon: string;
}

function FeaturedList() {
  const { setActiveNavItem } = useContext(ActiveNavItemContext);
  const [isDatasetsLoading, setIsDatasetsLoading] = useState(false);
  const [showSubFeaturedPanel, setShowSubFeaturedPanel] = useState(false);
  const [selectedFeatureList, setSelectedFeatureList] = useState<SubFeature[]>([]);
  const [enabledFeatureTabs, setEnabledFeatureTabs] = useState<string[]>(['Role Based', 'Accelerators']);
  const [displayFeatureTabs, setDisplayFeatureTabs] = useState(true);
  const selectedFeatureId = useRef<string>('projectDelivery');
  const [currentFeaturetab, setCurrentFeaturetab] = useState<string>('Role Based');

  const { t } = useTranslation();

  // List of featured items to be displayed

  const dispatch = useAppDispatch();

  // useEffect hook to fetch datasets for custom apps access on component mount
  useEffect(() => {
    fetchDatasetsForCustomAppsAccess();

    // Default behavior for "Role Based" tab
    setCurrentSubFeatureTab('Role Based');
  }, []);

  // Function to fetch datasets for custom apps access
  const fetchDatasetsForCustomAppsAccess = async () => {
    setIsDatasetsLoading(true);
    // Get custom apps details from a JSON file
    const customAppsDetails = customAppsData.custom_apps;
    // Fetch list of datasets from the API
    const listOfDatasets = (await Api.listDatasets(true, true, AppSkillId.DocCompletion))
      .data as ListOfExistingDataset[];
    // Filter custom apps based on user's access to datasets
    const listOfCustomAppsUserHasAccess = customAppsDetails.filter((customApp) =>
      listOfDatasets.some((dataset) => dataset._id === customApp.dataset_id)
    );
    // If the user has access to any app under Innovation Centers app, dispatch an action to save the details in the Redux store
    if (listOfCustomAppsUserHasAccess.length) {
      dispatch(
        saveInnovationCenterDetails({
          userHasAccess: true,
        })
      );
      dispatch(saveListOfCustomAppsUserHasAccess(listOfCustomAppsUserHasAccess));
    }
    setIsDatasetsLoading(false);
  };
  //common function added here
  const relatedSubFeatures = subFeaturedList.find((subFeature) => subFeature.associatedFeatureId === 'projectDelivery');

  const setCurrentSubFeatureTab = (selectedTab: string) => {
    if (relatedSubFeatures) {
      const defaultTab = (relatedSubFeatures.associatedSubFeatures as SubFeatureTab[]).find(
        (tabs) => tabs.label === selectedTab
      );
      if (defaultTab) {
        setSelectedFeatureList(defaultTab.content);
      }
    }
  };

  //handlers
  const handleProjectDelevery = React.useCallback(() => {
    setEnabledFeatureTabs(['Role Based', 'Accelerators']);
    setCurrentFeaturetab('Role Based');
    setDisplayFeatureTabs(true);
    selectedFeatureId.current = 'projectDelivery';

    if (relatedSubFeatures) {
      const activeTabToUse = currentFeaturetab || 'Role Based';
      const selectedTab = (relatedSubFeatures.associatedSubFeatures as SubFeatureTab[]).find(
        (tabs) => tabs.label === activeTabToUse
      );
      if (selectedTab) {
        setSelectedFeatureList(selectedTab.content);
      }
    }
  }, [subFeaturedList]);
  // Function to handle clicks on featured items
  const handleFeaturedClick = (feature: FeaturesType) => {
    if (feature.featureId === 'projectDelivery') {
      handleProjectDelevery();
    } else {
      setDisplayFeatureTabs(false);
      setEnabledFeatureTabs([]);
      setCurrentFeaturetab('');

      const relatedSubFeaturesID = subFeaturedList.find(
        (subFeature) => subFeature.associatedFeatureId === feature.featureId
      );

      if (relatedSubFeaturesID) {
        selectedFeatureId.current = feature.featureId;
        const contentList = relatedSubFeaturesID.associatedSubFeatures;
        const content = contentList.flatMap((item) => ('content' in item ? item.content : item)) as SubFeature[];
        setSelectedFeatureList(content);
        setShowSubFeaturedPanel(true);
      }
    }
  };

  const handleSubFeatureClick = () => {
    dispatch(
      saveInnovationCenterDetails({
        isActive: true,
      })
    );
    setActiveNavItem('apps');
  };

  const tabBodyContent = () => {
    return (
      <Box sx={{ display: 'flex', gap: 2, marginLeft: '0', justifyContent: 'left', marginTop: '1.25rem' }}>
        {selectedFeatureList.map((subFeature, index) => {
          return (
            <Card
              key={index}
              sx={{
                pointerEvents: !subFeature.enabled ? 'none' : 'auto',
                cursor: !subFeature.enabled ? 'not-allowed' : 'pointer',
                opacity: !subFeature.enabled ? 0.5 : 1,
                border: '1px solid #E5E5E5',
                borderRadius: '8px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                overflow: 'visible',
                width: '25%',
              }}
            >
              <CardContent sx={{ paddingBottom: 0 }}>
                <Typography sx={{ fontWeight: 500 }}>{subFeature.name}</Typography>
                <Typography sx={{ fontWeight: 400, fontSize: '13px', marginTop: '5px' }}>
                  {subFeature.description}
                </Typography>
              </CardContent>
              <Box sx={{ textAlign: 'right', padding: '15px 15px 10px 15px' }}>
                <Button
                  size="small"
                  variant="outlined"
                  sx={{ color: '#793196', borderColor: '#793196' }}
                  disabled={selectedFeatureId.current === 'projectDelivery'}
                  onClick={handleSubFeatureClick}
                >
                  {t('welcomepage.Featured.StartButton')}
                </Button>
              </Box>
            </Card>
          );
        })}
      </Box>
    );
  };

  const handleTabChange = (event: React.SyntheticEvent, newTab: string) => {
    setCurrentFeaturetab(newTab);
    setCurrentSubFeatureTab(newTab);
  };

  return (
    <Box sx={{ marginBottom: '70px' }}>
      <Typography sx={{ marginBottom: '15px' }}> {t('welcomepage.Featured.FeaturedWorkspace')}</Typography>
      <Box sx={{ display: 'flex', gap: 2, justifyContent: 'left' }}>
        {featuredList.map((feature, index) => {
          return (
            <Chip
              key={index}
              disabled={feature.featureDisable || isDatasetsLoading}
              sx={{
                padding: '8px 15px',
                background: selectedFeatureId.current === feature.featureId ? '#793196' : '',
                color: selectedFeatureId.current === feature.featureId ? '#fff' : '#000',
                '&.MuiChip-clickable:hover': {
                  background: selectedFeatureId.current === feature.featureId ? '#793196' : '',
                },
              }}
              variant="outlined"
              onClick={() => handleFeaturedClick(feature)}
              label={feature.featureName}
            />
          );
        })}
      </Box>
      {displayFeatureTabs && (
        <Box sx={{ marginTop: '20px' }}>
          <TabContext value={currentFeaturetab}>
            <Box>
              <TabList
                onChange={handleTabChange}
                aria-label="lab API tabs example"
                sx={{
                  '& .MuiTabs-flexContainer': {
                    borderRadius: '10px',
                    overflow: 'hidden',
                    display: 'inline-block',
                  },
                  '& .MuiTab-root': {
                    color: '#A1B1D2',
                    height: '35px',
                    minHeight: '30px',
                    textTransform: 'capitalize',
                    fontSize: '14px',
                    background: '#F1F3F8',
                  },
                  '& .MuiTab-root.Mui-selected': { color: '#ffff', background: '#3B414B', border: 'none' }, // Color for the selected tab
                  '& .MuiTabs-indicator': {
                    height: 'inherit',
                  },
                }}
              >
                {enabledFeatureTabs.map((subTab: string, index: number) => {
                  return <Tab key={index} label={subTab} value={subTab} disabled={subTab === 'Accelerators'} />;
                })}
              </TabList>
            </Box>
            {selectedFeatureId.current === 'projectDelivery' &&
              (currentFeaturetab === 'Role Based' || currentFeaturetab === 'Accelerators') &&
              tabBodyContent()}
          </TabContext>
        </Box>
      )}

      {showSubFeaturedPanel && selectedFeatureId.current !== 'projectDelivery' && tabBodyContent()}
    </Box>
  );
}

export default FeaturedList;
