import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InnovationCenter {
  userHasAccess: boolean;
  isActive: boolean;
  selectedDatasetIdForInnovationCenter: string;
}

interface CustomApps {
  name: string;
  dataset_id: string;
}

interface FeaturedList {
  innovationCenter: InnovationCenter;
  customAppDatasetId: string;
  listOfCustomAppsUserHasAccess: CustomApps[];
}

const initialState: FeaturedList = {
  innovationCenter: {
    userHasAccess: false,
    isActive: false,
    selectedDatasetIdForInnovationCenter: '',
  },
  customAppDatasetId: '',
  listOfCustomAppsUserHasAccess: [],
};

export const featuredSlice = createSlice({
  name: 'featured',
  initialState,
  reducers: {
    saveInnovationCenterDetails: (state, action: PayloadAction<Partial<InnovationCenter>>) => {
      state.innovationCenter = { ...state.innovationCenter, ...action.payload };
    },
    saveCustomAppDatasetId: (state, action: PayloadAction<string>) => {
      state.customAppDatasetId = action.payload;
    },
    saveListOfCustomAppsUserHasAccess: (state, action: PayloadAction<CustomApps[]>) => {
      state.listOfCustomAppsUserHasAccess = action.payload;
    },
  },
});

export const { saveInnovationCenterDetails, saveCustomAppDatasetId, saveListOfCustomAppsUserHasAccess } =
  featuredSlice.actions;

export default featuredSlice.reducer;
