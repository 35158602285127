import { useLocation } from 'react-router-dom';

const UsePageNavigation = () => {
  const location = useLocation();
  const isWelcomePage = location.pathname.replace('/', '').toLowerCase() === 'welcomepage';
  const isChatPage = location.pathname.replace('/', '').toLowerCase() === 'chat';

  return { isWelcomePage, isChatPage };
};

export default UsePageNavigation;
