import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../reduxStore/redux-hooks';

import { toggleModal, updateFileSelectionState, setFiles } from '../../reduxStore/slices/fileUploadSlice';
import LoadDynamicMessageOnChat from './LoadDynamicMessageOnChat';
import { LlmModel } from '../../data/models/LlmModel';

interface ResponseBoxProps {
  userFirstName: string; // Define the type of userFirstName here
  dynamicContent: ReactNode;
  modelIcon: string;
  modelTooltip?: string;
}

const ResponseBox: React.FC<ResponseBoxProps> = ({ userFirstName, dynamicContent, modelIcon, modelTooltip }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const responseBoxRef = useRef<HTMLDivElement>(null);
  const { fileUpload } = useAppSelector((state) => state.fileUpload);
  const clearValue = useAppSelector((state) => state.history.historyState.clearValue);
  const [modelIconToShow, setModelIconToShow] = useState(LlmModel.defaultModal.image);

  // Generalized drag state handler
  const handleDragState = (e: React.DragEvent<HTMLElement>, isDragging: boolean) => {
    e.preventDefault();
    dispatch(
      updateFileSelectionState({
        isFileDragging: isDragging,
      })
    );
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    dispatch(
      updateFileSelectionState({
        isFileDragging: false,
      })
    );
    const files = Array.from(e.dataTransfer.files);
    dispatch(setFiles(files));
    dispatch(toggleModal());
  };
  useEffect(() => {
    if (clearValue) {
      setModelIconToShow(modelIcon);
    }
  }, [clearValue]);

  useEffect(() => {
    if (responseBoxRef.current) {
      responseBoxRef.current.scrollTop = responseBoxRef.current.scrollHeight;
    }
  }, [dynamicContent]);

  return (
    <Box
      onDragOver={(e) => handleDragState(e, true)}
      onDragEnter={(e) => handleDragState(e, true)}
      onDragLeave={(e) => handleDragState(e, false)}
      onDrop={handleDrop}
      component="section"
      id="response-box"
      ref={responseBoxRef}
      sx={{
        maxHeight: { xs: 'calc(-19rem + 100vh)', sm: 'calc(-14rem + 100vh)' },
        height: `calc(100vh - ${fileUpload.uploadFile ? '16.9rem' : '13.9rem'})`,
      }}
    >
      <Box className="welcomeMsgOuter">
        <Box className="welcome-icon">
          <img id="changeicon" src={modelIconToShow} alt="ChatGPT icon" />
          {modelTooltip && (
            <Box className="toolTip">
              <strong>{modelTooltip}</strong>
            </Box>
          )}
        </Box>
        <Box className="welcome-msg">
          <Typography variant="h2" className="userName">
            {t('chatInterface.welcomeMessage', { name: userFirstName })}
          </Typography>
          <Typography>{t('chatInterface.greeting')}</Typography>
        </Box>
      </Box>
      {dynamicContent}
      <Box>{LoadDynamicMessageOnChat(responseBoxRef)}</Box>
    </Box>
  );
};

export default ResponseBox;
