import { NavigateFunction, To, NavigateOptions, useNavigate } from 'react-router-dom';
import { LogPageViewToGA } from '../../googleAnalytics/GoogleAnalytics';

// Custom hook that wraps useNavigate and adds Google Analytics tracking
export function UseTrackedNavigate(): (to: To, options?: NavigateOptions) => void {
  const navigate: NavigateFunction = useNavigate();

  function trackedNavigate(to: To, options?: NavigateOptions): void {
    const page = typeof to === 'string' ? to : to.pathname || '';

    LogPageViewToGA(page);

    // Call the original navigate function with the provided arguments
    navigate(to, options);
  }

  return trackedNavigate;
}
