import { datasetSharedUser } from '../../components/rightPanel/subpanel/dataset/DatasetList';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SelectedDataSet {
  activeDataSetName: string;
  activeDataSetId: string;
}
interface currentDataSet {
  currentDataSetName: string;
  currentDataSetId: string;
}

interface ownerDataSet {
  ownersDataList: { [datasetId: string]: string[] };
}
interface sharedDataSet {
  sharedDataList: { [datasetId: string]: datasetSharedUser[] };
}

interface DataSetState {
  selectedDataSet: SelectedDataSet;
  currentDataSet: currentDataSet;
  ownerDataSet: ownerDataSet;
  sharedDataSet: sharedDataSet;
}

const initialState: DataSetState = {
  selectedDataSet: {
    activeDataSetName: '',
    activeDataSetId: '',
  },
  currentDataSet: {
    currentDataSetName: '',
    currentDataSetId: '',
  },
  ownerDataSet: {
    ownersDataList: {},
  },
  sharedDataSet: {
    sharedDataList: {},
  },
};

export const dataSetSlice = createSlice({
  name: 'dataset',
  initialState,
  reducers: {
    selectedDataSet: (state, action: PayloadAction<SelectedDataSet>) => {
      state.selectedDataSet = action.payload;
    },
    currentDataSet: (state, action: PayloadAction<currentDataSet>) => {
      state.currentDataSet = action.payload;
    },
    ownerDataSet: (state, action: PayloadAction<ownerDataSet>) => {
      state.ownerDataSet = action.payload;
    },
    sharedDataSet: (state, action: PayloadAction<sharedDataSet>) => {
      state.sharedDataSet = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { selectedDataSet, currentDataSet, ownerDataSet, sharedDataSet } = dataSetSlice.actions;

export default dataSetSlice.reducer;
