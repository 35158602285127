import { SowCommitAiQueryResponse } from '../../../../reduxStore/slices/CustomAppsSlice';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

export function SowResponseTable({
  sowQueryResponse,
  selectedCustomAppSkills,
}: {
  sowQueryResponse: any;
  selectedCustomAppSkills: string;
}) {
  return (
    <>
      {(selectedCustomAppSkills === 'sow_commit_ai' || selectedCustomAppSkills === 'sow_commit_acc_ai') && (
        <Table sx={{ border: '1px solid black', marginBottom: '8px' }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: '700', border: '1px solid black' }}>Sl. No.</TableCell>
              <TableCell sx={{ fontWeight: '700', border: '1px solid black' }}>Check Commitment</TableCell>
              <TableCell sx={{ fontWeight: '700', border: '1px solid black' }}>Available Commitments</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sowQueryResponse.map((sowResponseList: SowCommitAiQueryResponse, index: number) => (
              <TableRow key={index}>
                <TableCell sx={{ border: '1px solid black' }}>{index}</TableCell>
                <TableCell sx={{ border: '1px solid black' }}>{sowResponseList.row.title}</TableCell>
                <TableCell sx={{ border: '1px solid black' }}>{sowResponseList.row.GenAI}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}

      {selectedCustomAppSkills === 'sow_predictor' && (
        <Table sx={{ border: '1px solid black', marginBottom: '8px' }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: '700', border: '1px solid black' }}>Attribute</TableCell>
              <TableCell sx={{ fontWeight: '700', border: '1px solid black' }}>Values</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.values(sowQueryResponse).map((item: any, index: number) => (
              <TableRow key={index}>
                <TableCell sx={{ border: '1px solid black' }}>{item[0]}</TableCell>
                <TableCell sx={{ border: '1px solid black' }}>{item[1]}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </>
  );
}

export default SowResponseTable;
