import { createContext } from 'react';

/**
 * The context that is used to pass the active nav item name between components. A non-null active nav item also means
 * the sub panel is activated.
 */
export const ActiveNavItemContext = createContext<{
  activeNavItem: null | string;
  setActiveNavItem: (newActiveNavItem: string | null) => void;
}>({
  activeNavItem: null,
  setActiveNavItem: () => {},
});
