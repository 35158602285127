import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import './CreateAgent.css';
import { useTranslation } from 'react-i18next';
import Api from '../../../../../data/api/Api';
import { LlmModel } from '../../../../../data/models/LlmModel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import * as assets from '../../../../../assets';
import { Controller, useForm } from 'react-hook-form';
import { ActiveNavItemContext } from '../../../RightPanelContext';
import { useAppDispatch, useAppSelector } from '../../../../../reduxStore/redux-hooks';
import { agentActivationMethod } from '../../../../../reduxStore/slices/AgentsSlice';
import Toolset from './toolSet/ToolSet';
import AttachDataset from './attach-dataset/AttachDataset';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from 'notistack';
import ShareAgent from '../shareAgent/ShareAgent';
import NumberInput from '../../../../../components/sharedComponents/numberInput/NumberInput';
import { maxOutputTokentooltipPoints } from '../../../../../components/staticComponents/StaticHtmlGenerator';
interface CreateAgentData {
  name: string;
  _id: string;
  model_name: string;
  owners: string[];
  acl?: {
    users: string[];
  };
}

enum AgentType {
  Basic = 'Basic',
  Dataset = 'Dataset',
  Toolset = 'Toolset',
}

function CreateAgent() {
  const { t } = useTranslation();
  const [llmModels, setLlmModels] = useState([LlmModel.defaultModal]);
  const [accordionExpanded, setAccordionExpanded] = React.useState<string | false>(false);
  const { setActiveNavItem } = useContext(ActiveNavItemContext);
  const listOfCreateAgentAccordions = [AgentType.Dataset, AgentType.Toolset];
  const dispatch = useAppDispatch();
  const temperature = 0.3;
  const [isAgentCreateInProcess, setIsAgentCreateInProcess] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const [showShareAgentDialog, setShowShareAgentDialog] = useState<boolean>(false);
  const { activationAgent } = useAppSelector((state) => state.agents.agentActivationProcess);

  //register create agent form fields with default values to useForm.
  //useForm is a custom hook for managing forms with ease.
  const { watch, control, getValues, setValue } = useForm({
    defaultValues: {
      dataset_id: null,
      description: '',
      instructions: '',
      model_name: llmModels[0].id as any, //type is defined as 'any' since model standard is something that is defined
      // only in UI and that model do not get generated in the schema file automatically.
      name: '',
      temperature: temperature,
      tools: null,
      type: AgentType.Basic,
      max_output_tokens: 256,
      top_k: 5,
    },
  });

  const handleAccordionChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setAccordionExpanded(isExpanded ? panel : false);
  };

  const handleCreateAgentFormSubmit = async () => {
    const createAgentFormData = getValues();
    setIsAgentCreateInProcess(true);
    try {
      Api.createAgent(createAgentFormData).then((createAgentResponse) => {
        if (createAgentResponse.data) {
          const createAgentData = createAgentResponse.data as CreateAgentData;
          //once agents is successfully created hold these agent details to show information on the chat for the user to perform some action.
          dispatch(
            agentActivationMethod({
              ShowAgentActivationDialogOnChat: true,
              activationAgent: {
                activationAgentName: createAgentData.name,
                activationAgentId: createAgentData._id,
                activationAgentModelName: createAgentData.model_name,
                owners: createAgentData.owners,
                acl: createAgentData.acl,
              },
            })
          );
          setShowShareAgentDialog(true);
        } else if (createAgentResponse.error) {
          enqueueSnackbar((createAgentResponse.error.message as string).toString(), { variant: 'error' });
        }
      });
    } catch (error) {
      enqueueSnackbar((error as Error).toString(), { variant: 'error' });
    }
    setIsAgentCreateInProcess(false);
  };

  const closeSubPanel = () => {
    setActiveNavItem(null);
    setShowShareAgentDialog(false);
  };

  const skillsSelected = (selectedSkills: string[]) => {
    if (selectedSkills.length) {
      //type is defined as any because the tools should be initialised always as
      //null(controller alwasy expects it to be type null) to match the AgentModifyRequest schema and update it as string[].
      setValue('tools', selectedSkills as any);
      setValue('type', AgentType.Toolset);
    } else {
      setValue('tools', null);
      setValue('type', AgentType.Basic);
    }
  };

  const attachDataset = (datasetId: string) => {
    if (datasetId) {
      //type is defined as any because the dataset_id should be initialised always as
      //null(controller alwasy expects it to be type null) to match the AgentModifyRequest schema and update it as string[].
      setValue('type', AgentType.Dataset);
      setValue('dataset_id', datasetId as any);
    } else {
      setValue('dataset_id', null);
      setValue('type', AgentType.Basic);
    }
  };

  useEffect(() => {
    // Load available LLM Models
    Api.listLlmModels().then((response) => {
      setLlmModels(response);
      //default model we have set is gpt-3.5 which is not supported for toolset agent.
      //hence showing gpt-4 as defult value in create agent page.
      setValue('model_name', response[6].id);
    });
  }, []);

  return (
    <Box className="create-agent-form">
      <Box className="create-agent-header">
        <Typography>{t('rightPanel.agent.createAgent.createNewAgent')}</Typography>
      </Box>
      <Box marginBottom={'40px'} className="agent-name-field">
        <Box marginLeft={'-106px'}>
          <img width={66} height={66} src={assets.agentIconEdit} alt="agent edit icon" />
        </Box>
        <Box flexGrow={1}>
          <InputLabel className="mui-form-label">{t('rightPanel.agent.createAgent.nameOfAgent') + '*'}</InputLabel>
          {/* The Controller from react-hook-form is used into our form,
          providing it with the necessary props and state to manage its 
          value, validation, and error handling, */}
          <Controller
            control={control}
            name="name"
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                variant="standard"
                fullWidth
                placeholder={t('rightPanel.agent.createAgent.placeHolderForAgentName')}
              />
            )}
          />
          <Box paddingTop={'5px'} fontSize={'14px'} color={'#793196'}>
            {t('rightPanel.agent.createAgent.createAgentHelperText')}
          </Box>
        </Box>
      </Box>
      <Box marginBottom={'40px'}>
        <InputLabel className="mui-form-label">{t('rightPanel.agent.createAgent.description')}</InputLabel>
        <Controller
          control={control}
          name="description"
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              variant="standard"
              fullWidth
              placeholder={t('rightPanel.agent.createAgent.placeHolderForDescription')}
            />
          )}
        />
      </Box>
      <Box marginBottom={'40px'} display={'flex'} gap={'50px'}>
        <Box width={'50%'}>
          <InputLabel className="mui-form-label">{t('rightPanel.agent.createAgent.languageModel')}</InputLabel>
          <Controller
            control={control}
            name="model_name"
            rules={{ required: false }}
            render={({ field }) => (
              <Select
                {...field}
                variant="standard"
                defaultValue={llmModels[0].id}
                sx={{ width: '50%', display: 'inline' }}
              >
                {llmModels.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </Box>

        <Box width={'20%'}>
          <Box sx={{ display: 'flex' }}>
            <InputLabel className="mui-form-label">{t('rightPanel.agent.createAgent.conversationStyle')}</InputLabel>
            <Tooltip
              title={
                <Box component={'ul'} paddingLeft={'10px'}>
                  <Box component="li">{t('rightPanel.agent.createAgent.temperatureTooltipPoints.firstPoint')}</Box>
                  <Box component="li">{t('rightPanel.agent.createAgent.temperatureTooltipPoints.secondPoint')}</Box>
                </Box>
              }
              arrow
            >
              <Icon sx={{ verticalAlign: 'middle', marginLeft: '5px', cursor: 'pointer' }}>
                <InfoIcon sx={{ width: '20px' }} />
              </Icon>
            </Tooltip>
          </Box>

          <Box sx={{ position: 'relative', marginTop: '15px' }}>
            <Box sx={{ position: 'absolute', top: '-10px', width: '100%' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography className="slider-marks">{t('rightPanel.agent.createAgent.accurate')}</Typography>
                <Typography className="slider-marks">{t('rightPanel.agent.createAgent.creative')}</Typography>
              </Box>
            </Box>
            <Box>
              <Controller
                control={control}
                name="temperature"
                rules={{ required: false }}
                render={({ field }) => (
                  <Slider {...field} name={'temperature'} step={0.1} min={0} max={1} valueLabelDisplay="on"></Slider>
                )}
              />
            </Box>
          </Box>
        </Box>

        <Box width={'20%'}>
          <Box sx={{ display: 'flex' }}>
            <InputLabel sx={{ paddingBottom: '5px' }} className="mui-form-label">
              {t('rightPanel.agent.createAgent.maxOutputToken')}
            </InputLabel>
            <Tooltip
              title={
                <Box component={'ul'} paddingLeft={'10px'}>
                  {maxOutputTokentooltipPoints.map((point) => {
                    const [tooltipTitle, tooltipDescription] = t(
                      `rightPanel.agent.createAgent.maxOutputTokenTooltipPoints.${point}`
                    ).split(': ');
                    return (
                      <Box component="li" key={point}>
                        <strong>{tooltipTitle} : </strong>
                        {tooltipDescription}
                      </Box>
                    );
                  })}
                </Box>
              }
              arrow
            >
              <Icon sx={{ verticalAlign: 'middle', marginLeft: '5px', cursor: 'pointer' }}>
                <InfoIcon sx={{ width: '20px' }} />
              </Icon>
            </Tooltip>
          </Box>
          <Controller
            control={control}
            name="max_output_tokens"
            rules={{ required: false }}
            render={({ field }) => (
              <NumberInput
                {...field}
                min={1}
                onChange={(event, newValue) => setValue('max_output_tokens', newValue ? newValue : 256)}
              />
            )}
          />
        </Box>
      </Box>
      <Box marginBottom={'40px'}>
        <InputLabel className="mui-form-label">{t('rightPanel.agent.createAgent.agentInstructions') + '*'}</InputLabel>
        <Typography sx={{ fontSize: 14 }}>{t('rightPanel.agent.createAgent.agentInstructionsHelper')}</Typography>

        <Controller
          control={control}
          name="instructions"
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              variant="standard"
              fullWidth
              multiline
              placeholder={t('rightPanel.agent.createAgent.placeHolderForInstructions')}
            />
          )}
        />
      </Box>
      {(getValues().tools || getValues().dataset_id) && (
        <Box paddingBottom={'10px'} fontSize={'14px'} color={'#793196'} fontWeight={700}>
          {/* This line displays a helper text message to the user, depending on whether they have selected a toolset or attached a dataset. */}
          {getValues().tools
            ? t('rightPanel.agent.createAgent.createAgentHelperTextForToolset')
            : t('rightPanel.agent.createAgent.createAgentHelperTextForDataset')}
        </Box>
      )}
      <Box marginBottom={'40px'} className="create-agent-accordion-section">
        {listOfCreateAgentAccordions.map((panel) => (
          <Accordion
            key={panel}
            expanded={accordionExpanded === panel}
            onChange={handleAccordionChange(panel)}
            className={`create-agent-accordion ${accordionExpanded === panel ? 'accordion-opened' : ''}`}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${panel}-content`}
              id={`${panel}-header`}
              // This condition ensures that the user can only select one option at a time.
              disabled={
                (watch('type') === AgentType.Toolset && panel === AgentType.Dataset) ||
                (watch('type') === AgentType.Dataset && panel === AgentType.Toolset)
              }
            >
              <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                <span>
                  {t(
                    `rightPanel.agent.createAgent.${panel === AgentType.Toolset ? 'toolBasedAgent' : 'attachDatasets'}`
                  )}
                </span>
                <img
                  width={20}
                  height={20}
                  src={watch('type') === panel ? assets.greenTickIcon : assets.validIconDisabled}
                  alt="valid icon disabled"
                />
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ color: '#000000' }}>
              {panel === AgentType.Toolset ? (
                <Toolset skillsSelected={skillsSelected} />
              ) : (
                <AttachDataset
                  attachDataset={attachDataset}
                  setIsAgentCreateInProcess={setIsAgentCreateInProcess}
                  attachDatasetTopK={(topKValue: number) => setValue('top_k', topKValue)}
                />
              )}
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
      <Box className="create-agent-actions">
        <Button variant="text" sx={{ textTransform: 'none', textDecoration: 'underline' }} onClick={closeSubPanel}>
          {t('rightPanel.agent.createAgent.cancel')}
        </Button>
        <LoadingButton
          type="button"
          variant="contained"
          sx={{
            textTransform: 'none',
            backgroundColor: '#793196',
          }}
          disabled={!watch('name') || !watch('instructions')}
          loading={isAgentCreateInProcess}
          onClick={handleCreateAgentFormSubmit}
        >
          {t('rightPanel.agent.createAgent.createAgent')}
        </LoadingButton>
      </Box>
      {showShareAgentDialog && (
        <Box>
          <ShareAgent
            setShowShareAgentDialog={setShowShareAgentDialog}
            agent={{
              name: activationAgent.activationAgentName,
              _id: activationAgent.activationAgentId,
              model_name: activationAgent.activationAgentModelName,
              owners: activationAgent.owners,
              acl: activationAgent.acl,
            }}
          />
        </Box>
      )}
    </Box>
  );
}

export default CreateAgent;
