import { ExternalId, AuthConfig } from '../pages/authConfig';
import { jwtDecode } from 'jwt-decode';
import { PublicClientApplication, RedirectRequest, SilentRequest } from '@azure/msal-browser';
import { getTenantName } from '../MsalConfig';
import { t } from 'i18next';

export function getCookie(name: any) {
  const nameEQ = name + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    const c = ca[i].trim();
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
export function setCookie(name: any, value: any, days: any) {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/';
}

// NOTE: This may be the wrong design pattern - the Singleton makes sense for Api, but since our UI should react to authentication
// state, we may want to consider some other form of global state management like Redux
export class Auth {
  private static instance: Auth;
  private msalInstance: PublicClientApplication;
  private externalId = ExternalId.tenantName;
  private authConfig = new AuthConfig();
  private accessTokenKey: string = 'access_token';
  private interactionInProgress: boolean = false;
  private static tokenExp: number = 0;

  private constructor() {
    // Private constructor to prevent instantiation from outside the class
    this.msalInstance = new PublicClientApplication(this.authConfig.getMsalConfig());
  }

  private async refreshInstance() {
    await this.setExternalId(this.externalId);
  }

  public static async getInstance(): Promise<Auth> {
    if (!Auth.instance) {
      Auth.instance = new Auth();
    }

    await Auth.instance.refreshInstance();

    return Auth.instance;
  }

  public getExternalId(): string {
    return this.externalId;
  }

  public async setExternalId(externalId: string): Promise<void> {
    this.externalId = externalId;
    this.msalInstance = new PublicClientApplication(this.authConfig.getMsalConfig());
    await this.msalInstance.initialize();
  }

  public getMsalInstance(): PublicClientApplication {
    return this.msalInstance;
  }

  public isLoggedIn(): boolean {
    return this.msalInstance.getAllAccounts().length > 0;
  }

  public async login(): Promise<void> {
    //await this.getCurrentAccessToken();
    //  await this.msalInstance.loginRedirect(this.authConfig.getRequestConfig(this.externalId));

    if (this.interactionInProgress) return;
    this.interactionInProgress = true; // Set interaction flag

    try {
      await this.getCurrentAccessToken();
    } finally {
      this.interactionInProgress = false; // Reset interaction flag
    }
  }

  public logout(): void {
    const accounts = this.msalInstance.getAllAccounts();

    this.invalidateToken();
    if (accounts.length > 0) {
      this.msalInstance.logoutRedirect({
        account: accounts[0],
        onRedirectNavigate: () => {
          const loggedInTenantName = getTenantName();
          if (loggedInTenantName === t('urlPathParameters.wipro')) {
            window.location.replace('/');
          } else {
            window.location.replace(loggedInTenantName);
          }
          return true;
        },
      });
    }
  }

  public async getFullName() {
    const token = await this.getCurrentAccessToken();
    const decodedToken = jwtDecode<JwtPayload>(token);
    return decodedToken.name;
  }

  public static needsAuth(): boolean {
    return Auth.tokenExp * 1000 > Date.now();
  }

  public invalidateToken() {
    localStorage.removeItem(this.accessTokenKey);
    this.msalInstance.clearCache();
  }

  public async getCurrentAccessToken(
    request: SilentRequest | RedirectRequest = this.authConfig.getRequestConfig()
  ): Promise<string> {
    const token = localStorage.getItem(this.accessTokenKey) ?? '';

    if (token) {
      const decodedToken = jwtDecode<JwtPayload>(token);
      Auth.tokenExp = decodedToken.exp;
      if (decodedToken.exp * 1000 > Date.now()) {
        return token;
      }
    }

    const accounts = this.msalInstance.getAllAccounts();
    if (accounts.length === 0) {
      await this.msalInstance.acquireTokenRedirect(this.authConfig.getRequestConfig());
    }

    this.msalInstance.setActiveAccount(accounts[0]);
    try {
      const response = await this.msalInstance.acquireTokenSilent(request);
      localStorage.setItem(this.accessTokenKey, response.accessToken);
      window.dispatchEvent(authEvent);
      return response.accessToken;
    } catch {
      await this.msalInstance.acquireTokenRedirect(this.authConfig.getRequestConfig());
      return '';
    }
  }

  public async getCurrentGraphToken(
    request: SilentRequest | RedirectRequest = this.authConfig.getRequestConfig()
  ): Promise<string> {
    try {
      const response = await this.msalInstance.acquireTokenSilent(request);
      const graphTokenResponse = await this.msalInstance.acquireTokenSilent({
        scopes: ['https://graph.microsoft.com/.default'],
        account: response.account,
      });
      return graphTokenResponse.accessToken;
    } catch (error) {
      console.error('Error acquiring Graph API token:', error);

      return '';
    }
  }

  public async shouldShowDisclaimer() {
    return getCookie('disclaimer') === null;
  }

  public acknowledgeDisclaimer(): void {
    setCookie('disclaimer', 'true', 1);
  }

  public async shouldShowWelcomePage() {
    return getCookie('WelcomePage') === null;
  }

  public acknowledgeWelcomePage(): void {
    setCookie('WelcomePage', 'true', 1);
  }
}

interface JwtPayload {
  exp: number;
  name: string;
  preferred_username: string;
}

export async function getAuthInstance(): Promise<Auth> {
  return Auth.getInstance();
}

export const needsAuth = Auth.needsAuth();
export const authEvent = new CustomEvent('authorization');
