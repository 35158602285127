import React from 'react';
import { Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { SUB_PANEL_WIDTH, SUB_PANEL_WIDTH_SX, usePortrait } from '../../../staticComponents/StaticHtmlGenerator';

function CloseRightNavPanel(closeSubPanel: () => void) {
  const portrait = usePortrait();
  return (
    <Box
      id="close-button"
      sx={{
        position: 'absolute',
        right: {
          xs: portrait ? SUB_PANEL_WIDTH_SX : SUB_PANEL_WIDTH,
          md: 351,
        },
        top: {
          xs: portrait ? 24 : 34,
          md: 34,
        },
        width: 29,
        height: 40,
        backgroundColor: '#F2F2F2',
        borderRadius: '8px 0 0 8px',
        padding: '0',
        cursor: 'pointer',
        '&:hover': {
          color: '#AF2007',
        },
      }}
      onClick={closeSubPanel}
    >
      <CloseIcon sx={{ marginTop: '7px', marginLeft: '1px' }} />
    </Box>
  );
}

export default CloseRightNavPanel;
