import { getAuthInstance } from './Auth';

class User {
  public static empty = new User();
  private static instance: User;
  public fullName: string = '';

  private constructor() {
    // Private constructor to prevent instantiation from outside the class
  }

  private async initialize() {
    this.fullName = await (await getAuthInstance()).getFullName();
  }

  public static async getInstance(): Promise<User> {
    const user = new User();
    await user.initialize();

    return user;
  }

  /// Returns the first name of the user
  public firstName() {
    return this.fullName.split(' ')[0];
  }

  /// Returns the first and last initials of the user
  public initials() {
    const initials = this.fullName
      .split(' ')
      .map((a) => a[0])
      .join('');

    if (initials.length > 1) {
      return initials[0] + initials[initials.length - 1];
    } else {
      return initials;
    }
  }
}

export default User;
