import { Box } from '@mui/material';
import React from 'react';
import * as assets from '../../../../assets';
import { useTranslation, Trans } from 'react-i18next';

function QETPlatform() {
  const { t } = useTranslation();

  return (
    <>
      <Box className="msgBox">
        <Box className="welcome-icon">
          <img id="changeIcon" src={assets.ChatGptIcon} alt="chat gpt logo" />
        </Box>

        <Box className="chat-content" sx={{ '& p': { marginTop: 0 } }}>
          <Box fontWeight={700}>
            <Trans i18nKey="rightPanel.customapps.redirectingToQET">
              <a href={t('rightPanel.customapps.qetLink')} target="_blank" rel="noreferrer" />
            </Trans>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default QETPlatform;
