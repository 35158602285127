import { Box, CircularProgress, List, ListItem, ListItemText, Typography } from '@mui/material';
import { ConversationData } from '../History';
import { useTranslation } from 'react-i18next';
import BasicPopover from '../../../../sharedComponents/BasicPopover';
import ImageButton from '../../../../sharedComponents/ImageButton';
import * as assets from '../../../../../assets';
import HistoryPopoverData from '../editHistory/EditHistory';
import { getFormattedDateAndTime } from '../../../../staticComponents/staticUtiles';
import { buttonStyle } from '../../dataset/Dataset';

interface ChatHistoryListProps {
  enableLoader: boolean;
  historyData: ConversationData[];
  historyDayIdentifier: string;
  fetchAndProcessConversationMessages: (conversationId: string, conversationHeading: string, party_id: string) => void;
}

const ChatHistoryList = ({
  enableLoader,
  historyData,
  historyDayIdentifier,
  fetchAndProcessConversationMessages,
}: ChatHistoryListProps) => {
  const { t } = useTranslation();

  return (
    <List
      className="dataList_ul"
      sx={{
        overflowY: 'auto',
        padding: 0,
        height: historyDayIdentifier === t('history.lastSevenDaysHistory') ? '340px' : '176px',
      }}
    >
      {enableLoader ? (
        <CircularProgress size={20} sx={{ marginTop: '10px' }} />
      ) : historyData && historyData.length > 0 ? (
        historyData.map((listItem) => {
          const { dateString, timeString } = getFormattedDateAndTime(listItem.last_updated);

          return (
            <Box key={listItem.conversation_id}>
              <Box>
                <ListItem
                  sx={{
                    color: '#256AFF',
                    transition: 'all 300ms ease-in-out',
                    padding: 0,
                    cursor: 'pointer',
                    display: 'block',
                    width: '95%',
                  }}
                >
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box>
                      <ListItemText
                        onClick={() =>
                          fetchAndProcessConversationMessages(
                            listItem.conversation_id,
                            listItem.title,
                            listItem.party_id
                          )
                        }
                      >
                        <Typography
                          sx={{
                            width: '275px',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {listItem.title}
                        </Typography>
                      </ListItemText>
                    </Box>
                    <BasicPopover
                      srcComponent={
                        <ImageButton
                          className="buttonShowOnHover"
                          srcFile={assets.dots}
                          style={{
                            ...buttonStyle,
                          }}
                        />
                      }
                      contentComponent={
                        <HistoryPopoverData
                          conversationHeading={listItem.title}
                          conversationID={listItem.conversation_id}
                        />
                      }
                    />
                  </Box>

                  <Box sx={{ display: 'flex', justifyContent: 'space-between', color: '#7E7E7E', width: '100%' }}>
                    <Typography style={{ fontSize: '13px', marginBottom: '1rem' }}>{dateString}</Typography>
                    <Typography style={{ fontSize: '13px' }}>{timeString}</Typography>
                  </Box>
                </ListItem>
              </Box>
            </Box>
          );
        })
      ) : (
        <Typography>{t('history.noHistoryToShow')}</Typography>
      )}
    </List>
  );
};

export default ChatHistoryList;
