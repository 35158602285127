import * as assets from '../../assets';
import Input from '@mui/material/Input';
import { Box, useTheme } from '@mui/material';
import ImageButton from '../../components/sharedComponents/ImageButton';
import { useAppDispatch, useAppSelector } from '../../reduxStore/redux-hooks';
import { setFiles, toggleModal } from '../../reduxStore/slices/fileUploadSlice';
import DatasetModal, { supportedFileTypes } from '../../components/rightPanel/subpanel/dataset/DatasetModal';

interface fileUploadProps {
  disabled: boolean;
}

const FileUpload = ({ disabled }: fileUploadProps) => {
  const dispatch = useAppDispatch();
  const { datasetModals, fileUpload } = useAppSelector((state) => state.fileUpload);
  const theme = useTheme();
  const fileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files: File[] = Array.from(e.target.files as FileList);
      dispatch(setFiles(files));
    }
    dispatch(toggleModal());
  };

  return (
    <>
      <Box
        component="div"
        sx={{
          [theme.breakpoints.down('sm')]: {
            position: 'static',
          },
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            left: fileUpload.uploadedFiles.length > 0 ? '-13px' : '11px',
          }}
        >
          <Input
            id="file-upload"
            type="file"
            sx={{
              opacity: '0',
              zIndex: '99',
              left: fileUpload.uploadedFiles.length > 0 ? '10px' : '-10px',
              width: '1.86rem',
              pointerEvents: disabled ? 'none' : 'auto',
            }}
            inputProps={{
              multiple: true,
              accept: supportedFileTypes,
            }}
            onChange={fileInput}
          />
          <label htmlFor="file-upload" className="custom-file-upload" style={{ position: 'absolute', left: '0px' }}>
            <ImageButton disabled={disabled} srcFile={assets.attachFile} />
          </label>
        </Box>
      </Box>
      {datasetModals.activeModalDatasets && <DatasetModal />}
    </>
  );
};

export default FileUpload;
