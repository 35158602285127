import React from 'react';
import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import * as assets from '../../assets';
import { useAppDispatch, useAppSelector } from '../../reduxStore/redux-hooks';
import { saveCustomAppDatasetId, saveInnovationCenterDetails } from '../../reduxStore/slices/FeaturedSlice';
import customAppsData from '../rightPanel/subpanel/customApps/CustomApps.json';

function InnovationCenter() {
  const dispatch = useAppDispatch();
  const { listOfCustomAppsUserHasAccess } = useAppSelector((state) => state.featured);
  const customAppsDetails = customAppsData.custom_apps;

  const handleInnovationCenterOptionSelect = (innovationCenterOption: string) => {
    const customAppsDetails = customAppsData.custom_apps;
    const datasetSelected = customAppsDetails.find((customApp) => customApp.value === innovationCenterOption);
    if (datasetSelected) {
      dispatch(
        saveInnovationCenterDetails({
          selectedDatasetIdForInnovationCenter: datasetSelected.dataset_id,
        })
      );
      dispatch(saveCustomAppDatasetId(datasetSelected.dataset_id));
    }
  };

  return (
    <Box className="msgBox">
      <Box className="welcome-icon">
        <img id="changeicon" src={assets.chatGpt_logo} alt="chat gpt logo" />
        <Box className="toolTip">
          <strong>3.5</strong>
        </Box>
      </Box>
      <Box className="chat-content" sx={{ '& p': { marginTop: 0 } }}>
        <Typography>
          Welcome to the <strong>Global Innovation Center</strong>, where the future of technology comes to life!
        </Typography>
        <Typography marginBottom={'1rem'}>
          Immerse yourself in the &quot;Art of the Possible&quot; with dynamic experiences and cutting-edge
          technologies.
        </Typography>
        <Box>
          <Typography>We&apos;re here to support you with information on:</Typography>
          <FormControl>
            <RadioGroup
              aria-labelledby="innovation-center-radio-buttons-group-label"
              name="radio-buttons-group"
              onChange={(event) => handleInnovationCenterOptionSelect(event.target.value)}
            >
              {customAppsDetails.map((app, index) => (
                <FormControlLabel
                  key={index}
                  value={app.value}
                  control={<Radio />}
                  label={<Typography sx={{ fontWeight: '700' }}>{app.name}</Typography>}
                  disabled={!listOfCustomAppsUserHasAccess.some((customApp) => customApp.dataset_id === app.dataset_id)}
                  sx={{
                    opacity: !listOfCustomAppsUserHasAccess.some((customApp) => customApp.dataset_id === app.dataset_id)
                      ? 0.5
                      : 1,
                  }}
                />
              ))}
            </RadioGroup>
          </FormControl>
          <Typography>Please select anyone of the above to ask your queries.</Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default InnovationCenter;
