import { AgentSharedUserDetails } from '../../components/rightPanel/subpanel/agents/shareAgent/ShareAgent';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Acl {
  users: string[];
}
interface ActiveAgent {
  activeAgentName: string;
  activeAgentId: string;
  activeAgentModelName: string;
  owners: string[];
  acl?: Acl;
}

interface ActivationAgent {
  activationAgentName: string;
  activationAgentId: string;
  activationAgentModelName: string;
  owners: string[];
  acl?: Acl;
}

interface AgentActivationProcess {
  ShowAgentActivationDialogOnChat: boolean;
  activationAgent: ActivationAgent;
}

interface AgentSharedUsers {
  users: AgentSharedUserDetails[];
}

interface AgentsState {
  activeAgent: ActiveAgent;
  agentActivationProcess: AgentActivationProcess;
  agentSharedUsers: AgentSharedUsers;
}

const initialState: AgentsState = {
  activeAgent: {
    activeAgentName: '',
    activeAgentId: '',
    activeAgentModelName: '',
    owners: [],
    acl: { users: [] },
  },
  agentActivationProcess: {
    ShowAgentActivationDialogOnChat: false,
    activationAgent: {
      activationAgentName: '',
      activationAgentId: '',
      activationAgentModelName: '',
      owners: [],
      acl: { users: [] },
    },
  },
  agentSharedUsers: {
    users: [],
  },
};

export const agentsSlice = createSlice({
  name: 'agents',
  initialState,
  reducers: {
    activeAgent: (state, action: PayloadAction<ActiveAgent>) => {
      state.activeAgent = action.payload;
    },
    agentActivationMethod: (state, action: PayloadAction<AgentActivationProcess>) => {
      state.agentActivationProcess = action.payload;
    },
    agentSharedUsers: (state, action: PayloadAction<AgentSharedUsers>) => {
      state.agentSharedUsers = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { activeAgent, agentActivationMethod, agentSharedUsers } = agentsSlice.actions;

export default agentsSlice.reducer;
