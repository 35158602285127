import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button } from '@mui/material';
import RadioSelect from './RadioSelect';
import Api, { SkillId } from '../../../../data/api/Api';
import { useAppDispatch, useAppSelector } from '../../../../reduxStore/redux-hooks';
import {
  toggleModal,
  updateFileUploadState,
  updateFileSelectionState,
  updateDatasetState,
} from '../../../../reduxStore/slices/fileUploadSlice';
import { useTranslation } from 'react-i18next';
import NewDataset from './NewDataset';
import ExistingDataset from './ExistingDataset';
import { useEffect, useState } from 'react';
import { currentDataSet, selectedDataSet } from '../../../../reduxStore/slices/DataSetSlice';
import { useSnackbar } from 'notistack';
import UsePageNavigation from '../../../sharedComponents/customHooks/usePageNavigation/UsePageNavigation';
import {
  chatPageNavigation,
  AppSkillId,
  customAppsSkillIds,
} from '../../../../components/staticComponents/StaticHtmlGenerator';
import {
  saveSowCommitAiQueryResponse,
  saveSowPredictorQueryResponse,
  SowCommitAiQueryResponse,
  SowPredictorQueryResponse,
} from '../../../../reduxStore/slices/CustomAppsSlice';
import { UseTrackedNavigate } from '../../../../components/sharedComponents/customHooks/useTrackedNavigate/UseTrackedNavigate';

export interface ListOfExistingDataset {
  allow_all_access: boolean;
  desc: string;
  files: [];
  name: string;
  owners: string[];
  tenant_id: string;
  _id: string;
}

export const supportedFileTypes = [
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'message/rfc822',
  '.msg',
  'text/plain',
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
];

export default function DatasetModal() {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [datasetRadioSelect, setdatasetRadioSelect] = useState<string>(t('fileUpload.newDataset'));
  const dispatch = useAppDispatch();
  const { datasetModals, fileUpload, fileSelection, dataset } = useAppSelector((state) => state.fileUpload);
  const handleClose = () => dispatch(toggleModal());
  const navigate = UseTrackedNavigate();
  const { isWelcomePage } = UsePageNavigation();
  const { isCustomAppSelected, selectedCustomAppSkill } = useAppSelector((state) => state.customApps.choosenCustomApp);

  const validateFileUpload = (uploadedFiles: File[]) => {
    const BYTES_IN_KILOBYTE = 1024;
    const MAX_FILE_SIZE_MB = 100;
    const MAX_FILES = 10;

    // Check the number of files
    if (uploadedFiles.length > MAX_FILES) {
      enqueueSnackbar(t('fileUpload.uploadMax10Files'), { variant: 'error' });
      handleClose();
      return false;
    }

    // Calculate total size of uploaded files
    const TOTAL_FILES_SIZE_IN_BYTES = uploadedFiles.reduce((total, file) => total + file.size, 0);
    const TOTAL_FILES_SIZE_IN_MB = TOTAL_FILES_SIZE_IN_BYTES / (BYTES_IN_KILOBYTE * BYTES_IN_KILOBYTE);

    // Check the total size of files
    if (TOTAL_FILES_SIZE_IN_MB > MAX_FILE_SIZE_MB) {
      enqueueSnackbar(t('fileUpload.uploadMax100MB'), { variant: 'error' });
      handleClose();
      return false;
    }
    return true;
  };

  const readFiles = async () => {
    // Validate file size and number
    if (!validateFileUpload(fileUpload.uploadedFiles)) {
      return; //Doing a early Return
    }
    handleClose();

    const checkIfUploadedFilesSupport = fileUpload.uploadedFiles.every((file) =>
      supportedFileTypes.includes(file.type)
    );
    if (!checkIfUploadedFilesSupport) {
      enqueueSnackbar(t('fileUpload.supportedFiles'), { variant: 'error' });
      return; //Return and stop processing further if unsupported files are uploaded.
    }

    dispatch(
      updateFileSelectionState({
        responseMessage: true,
      })
    );

    dispatch(
      updateFileUploadState({
        uploadFileMessage: t('fileUpload.uploadFileMessage.uploadStatus1'),
        uploadFile: true,
      })
    );

    let datasetId: string = '';
    let sowQuery;
    if (datasetRadioSelect === t('fileUpload.newDataset')) {
      const createdDataset = await Api.createDataset(dataset.datasetName);
      datasetId = createdDataset.data._id;
      await Api.uploadDataset(createdDataset.data._id, fileUpload.uploadedFiles);
    } else {
      datasetId = dataset.selectedDatasetId;
      if (fileSelection.isReplaceFileCheckboxChecked) {
        // Filters out user uploaded files which are not in the selected dataset
        const filteredFile = fileUpload.uploadedFiles.filter(
          (file) => !fileUpload.listOfFilteredUploadedFilesExistInDataset.includes(file.name)
        );
        // Makes dataset ingest api call with the filtered files
        await Api.uploadDataset(datasetId, filteredFile);
      }
      // Makes dataset ingest api call with all the user uploaded files
      await Api.uploadDataset(datasetId, fileUpload.uploadedFiles);
    }

    dispatch(
      updateFileUploadState({
        uploadFileMessage: t('fileUpload.uploadFileMessage.uploadStatus2'),
      })
    );
    await Api.getDataset(datasetId);
    const prepare = await Api.prepareDataset(datasetId, AppSkillId.DocCompletion);

    let workflowData = prepare.data;
    dispatch(
      updateFileUploadState({
        uploadFileMessage: t('fileUpload.uploadFileMessage.uploadStatus3'),
      })
    );
    const delay = 1000 * (fileUpload.uploadedFiles.length || 1);
    while (workflowData.status !== 'Completed') {
      const workflow = await Api.getWorkflowForDataset(datasetId, workflowData._id);
      if (workflow.data) {
        workflowData = workflow.data;
      }
      if (workflowData.status === 'Failed') {
        dispatch(
          updateFileUploadState({
            uploadFileMessage: t('fileUpload.uploadFileMessage.failurePrepareMesssage'),
          })
        );
        return;
      }
      await new Promise((r) => setTimeout(r, delay));
    }
    if (workflowData.status === 'Completed') {
      dispatch(
        updateFileUploadState({
          uploadFileMessage: t('fileUpload.uploadFileMessage.uploadStatus4'),
        })
      );
      // Fetch Custom Apps SOW response
      if (isCustomAppSelected) {
        //need to refactor the code
        const prepareSow = await Api.prepareDataset(datasetId, selectedCustomAppSkill as SkillId);
        // When custom app is sent for prepare, the dataset returns workflow_id instead of _id.
        // So the below sowWorkflowData has given type as any
        const sowWorkflowData: any = prepareSow.data;
        const delayedTime = 1000;
        const sowDelay = delayedTime * (fileUpload.uploadedFiles.length || 1);
        while (sowWorkflowData.status !== 'Completed') {
          const sowWorkflow = await Api.getWorkflowForDataset(datasetId, sowWorkflowData.workflow_id);
          if (sowWorkflow.data) {
            sowWorkflowData.status = sowWorkflow.data.status;
          }
          if (sowWorkflowData.status === 'Failed') {
            return;
          }
          await new Promise((r) => setTimeout(r, sowDelay));
        }
        sowQuery = (await Api.fetchSowQuery(datasetId, selectedCustomAppSkill as SkillId)).data;
      }
      dispatch(
        updateFileUploadState({
          uploadFileMessage: t('fileUpload.uploadFileMessage.uploadStatus5'),
        })
      );

      dispatch(
        updateFileUploadState({
          uploadFile: false,
        })
      );

      dispatch(
        updateFileSelectionState({
          responseMessage: false,
        })
      );

      dispatch(
        updateDatasetState({
          makeActiveDataset: true,
        })
      );

      dispatch(
        currentDataSet({
          currentDataSetName: dataset.datasetName,
          currentDataSetId: datasetId,
        })
      );

      //Dispatching the SOW response to Chat Box and Showing the active dataset name
      if (isCustomAppSelected) {
        dispatch(
          selectedDataSet({
            activeDataSetName: dataset.datasetName,
            activeDataSetId: datasetId,
          })
        );
        dispatch(
          customAppsSkillIds.includes(selectedCustomAppSkill)
            ? saveSowCommitAiQueryResponse(sowQuery as SowCommitAiQueryResponse[])
            : saveSowPredictorQueryResponse(sowQuery as SowPredictorQueryResponse)
        );
      }
    }
    if (isWelcomePage) {
      navigate(chatPageNavigation);
    }
  };

  const handleInitialComponentData = async () => {
    dispatch(
      updateDatasetState({
        datasetName: fileUpload.uploadedFiles[0].name,
      })
    );
    const listOfExistingDataset = (await Api.listDatasets(true, false, selectedCustomAppSkill as SkillId))
      .data as ListOfExistingDataset[];

    dispatch(
      updateDatasetState({
        listOfUserOwnedDataset: listOfExistingDataset,
      })
    );
  };

  useEffect(() => {
    handleInitialComponentData();
  }, []);

  return (
    <Box>
      <Modal open={datasetModals.activeModalDatasets}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            position: 'absolute' as const,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            minHeight: 450,
            maxWidth: '60vw',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 6,
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1.25rem' }}>
            <Typography
              sx={{
                display: 'flex',
                justifyContent: 'center',
                fontWeight: '700',
                fontSize: '24px',
                lineHeight: '24px',
              }}
            >
              {datasetRadioSelect === 'New Dataset' ? t('fileUpload.newdataset') : t('fileUpload.updatedataset')}
            </Typography>
            <Typography sx={{ borderTop: '1px dashed grey' }}></Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
                padding: '0.5rem 2.5rem',
              }}
            >
              <RadioSelect setdatasetRadioSelect={setdatasetRadioSelect} />
              {datasetRadioSelect === 'New Dataset' ? <NewDataset /> : <ExistingDataset />}
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: '1.5rem',
            }}
          >
            <Button onClick={handleClose} sx={{ textDecoration: 'underline' }}>
              {t('rightPanel.cancel')}
            </Button>
            <button
              disabled={fileSelection.isDatasetButtonDisabled}
              style={{
                background: fileSelection.isDatasetButtonDisabled ? '#DADADB' : '#793196',
                color: fileSelection.isDatasetButtonDisabled ? '#737373' : 'white',
              }}
              onClick={readFiles}
            >
              {datasetRadioSelect === 'New Dataset' ? t('fileUpload.createdataset') : t('fileUpload.updatedataset')}
            </button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
