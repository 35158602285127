import React, { useEffect, useState, useCallback, useRef } from 'react';
import Api, { SkillId } from '../../../../data/api/Api';
import CircularProgress from '@mui/material/CircularProgress';
import SearchList from '../../../sharedComponents/SearchList';
import BasicPopover from '../../../sharedComponents/BasicPopover';
import EditDataset from './EditDataset';
import * as assets from '../../../../assets';
import { Box } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../../reduxStore/redux-hooks';
import {
  selectedDataSet,
  ownerDataSet,
  sharedDataSet,
  currentDataSet,
} from '../../../../reduxStore/slices/DataSetSlice';
import { updateDatasetState } from '../../../../reduxStore/slices/fileUploadSlice';
import { useTranslation } from 'react-i18next';
import ImageButton from '../../../sharedComponents/ImageButton';
import { useSnackbar } from 'notistack';
import { showUserActionContentOnChat } from '../../../../reduxStore/slices/NotifyUserActionContentSlice';
import { buttonStyle } from './Dataset';
import { chatPageNavigation } from '../../../../components/staticComponents/StaticHtmlGenerator';
import UsePageNavigation from '../../../sharedComponents/customHooks/usePageNavigation/UsePageNavigation';
import {
  SowCommitAiQueryResponse,
  SowPredictorQueryResponse,
  saveSowCommitAiQueryResponse,
  saveSowPredictorQueryResponse,
} from '../../../../reduxStore/slices/CustomAppsSlice';
import { AppSkillId, customAppsSkillIds } from '../../../../components/staticComponents/StaticHtmlGenerator';
import { UseTrackedNavigate } from '../../../../components/sharedComponents/customHooks/useTrackedNavigate/UseTrackedNavigate';

interface Acl {
  users: string[]; // Array of user IDs
}
interface Dataset {
  _id: string;
  name: string;
  owners: string[];
  acl: Acl;
}
interface SearchListItem {
  _id: string;
  name: string;
  owners: string[];
  users: string[];
}
export interface datasetSharedUser {
  id: string;
  name: string;
}

interface DatasetListProps {
  selectedApp: string;
}

const DatasetList: React.FC<DatasetListProps> = ({ selectedApp }) => {
  const dispatch = useAppDispatch();

  const { activeDataSetName, activeDataSetId } = useAppSelector((state) => state.dataset.selectedDataSet);
  const prevDataSetNameRef = useRef(activeDataSetName);
  const prevDataSetIdRef = useRef(activeDataSetId);
  const [datasets, setDatasets] = useState<SearchListItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [ownersData, setOwnersData] = useState<{ [key: string]: string[] }>({});
  const [datasetSharedUsersList, setDatasetSharedUsersList] = useState<{ [key: string]: datasetSharedUser[] }>({});
  const navigate = UseTrackedNavigate();
  const { isWelcomePage } = UsePageNavigation();
  const { isCustomAppSelected, selectedCustomAppSkill } = useAppSelector((state) => state.customApps.choosenCustomApp);

  const fetchListofDatasets = useCallback(async () => {
    try {
      let loadDatasets;
      if (isCustomAppSelected) {
        loadDatasets = await Api.listDatasets(true, true, selectedCustomAppSkill as SkillId);
      } else {
        loadDatasets = await Api.listDatasets(true, true, AppSkillId.DocCompletion);
      }
      const datasets = loadDatasets.data as Dataset[];
      console.log(datasets);
      const isNoDatasetExists = datasets.find((dataset) => dataset._id === activeDataSetId);

      //Clearing the Custom App Dataset value
      if (!isNoDatasetExists && !isCustomAppSelected) {
        dispatch(
          selectedDataSet({
            activeDataSetId: '',
            activeDataSetName: '',
          })
        );
        dispatch(
          currentDataSet({
            currentDataSetName: '',
            currentDataSetId: '',
          })
        );
        dispatch(
          updateDatasetState({
            makeActiveDataset: false,
          })
        );
      }
      const filteredAndSortedDatasets = datasets
        .filter((dataset) => dataset.name)
        .sort((a, b) => a.name.localeCompare(b.name));

      if (!filteredAndSortedDatasets.length) enqueueSnackbar(t('snackbar.alertMessage.noDataset'));

      const searchListItems = filteredAndSortedDatasets.map((dataset) => ({
        _id: dataset._id,
        name: dataset.name,
        owners: dataset.owners,
        users: dataset.acl?.users || [], // Fetch users from acl
      }));

      setDatasets(searchListItems);
    } catch (err) {
      enqueueSnackbar((err as Error).toString());
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, t, selectedApp]);

  useEffect(() => {
    fetchListofDatasets();
  }, [fetchListofDatasets]);

  const getGraphData = useCallback(
    async (dataset: SearchListItem) => {
      try {
        // Fetch user details using the acl.users from the original Dataset interface
        const aclResponse = await Api.getUserDetailsByIds(dataset.users);
        const datasetSharedList: datasetSharedUser[] = dataset.users.map((id) => ({
          id,
          name: aclResponse[id] || id,
        }));

        const ownersName = await Api.getUserDetailsByIds(dataset.owners);
        const ownerList = dataset.owners.map((id) => ownersName[id] || id);

        //for local data fetch
        setOwnersData((prev) => ({ ...prev, [dataset._id]: ownerList }));
        setDatasetSharedUsersList((prev) => ({ ...prev, [dataset._id]: datasetSharedList }));

        //for redux data fetch
        dispatch(
          ownerDataSet({
            ownersDataList: { ...ownersData, [dataset._id]: ownerList },
          })
        );
        dispatch(
          sharedDataSet({
            sharedDataList: { ...datasetSharedUsersList, [dataset._id]: datasetSharedList },
          })
        );
      } catch (error) {
        enqueueSnackbar(t('snackbar.alertMessage.errorGetGraphData'));
        console.log(error);
      }
    },
    [dispatch, enqueueSnackbar, t, ownersData, datasetSharedUsersList]
  );

  useEffect(() => {
    // Effect for handling dataset name changes
    const isDatasetNameChanged = activeDataSetName !== prevDataSetNameRef.current;
    const isDatasetIdChanged = activeDataSetId !== prevDataSetIdRef.current;

    if (isDatasetNameChanged || isDatasetIdChanged) {
      if (activeDataSetId) {
        if (!isCustomAppSelected) {
          dispatch(showUserActionContentOnChat(t('fileUpload.activeMessage', { fileName: activeDataSetName })));
        }
        prevDataSetNameRef.current = activeDataSetName;
      } else if (activeDataSetId === '' && activeDataSetName !== prevDataSetNameRef.current) {
        if (!isCustomAppSelected) {
          dispatch(
            showUserActionContentOnChat(t('fileUpload.unloadMessage', { fileName: prevDataSetNameRef.current }))
          );
        }
        prevDataSetNameRef.current = '';
      }
    }
  }, [activeDataSetName, activeDataSetId, prevDataSetNameRef.current]);

  const handleDatasetSelect = async (dataset: SearchListItem) => {
    dispatch(
      selectedDataSet({
        activeDataSetName: dataset.name,
        activeDataSetId: dataset._id,
      })
    );
    if (!ownersData[dataset._id] || !datasetSharedUsersList[dataset._id]) {
      getGraphData(dataset);
    }
    if (isCustomAppSelected) {
      const sowresponse = await Api.fetchSowQuery(dataset._id, selectedCustomAppSkill as SkillId);

      dispatch(
        customAppsSkillIds.includes(selectedCustomAppSkill)
          ? saveSowCommitAiQueryResponse(sowresponse.data as SowCommitAiQueryResponse[])
          : saveSowPredictorQueryResponse(sowresponse.data as SowPredictorQueryResponse)
      );
    }
    if (isWelcomePage) {
      navigate(chatPageNavigation);
    }
  };

  const renderEditPopover = (dataset: SearchListItem) => {
    const handleOpenPopover = () => {
      if (!ownersData[dataset._id] || !datasetSharedUsersList[dataset._id]) {
        getGraphData(dataset);
      }
    };

    return (
      <BasicPopover
        srcComponent={<ImageButton srcFile={assets.dots} style={buttonStyle} />}
        onOpen={handleOpenPopover}
        contentComponent={
          <EditDataset
            activeDataSetName={dataset.name}
            activeDataSetId={dataset._id}
            currentDatasetId={dataset._id}
            currentDatasetName={dataset.name}
            isFromDatasetList={true}
            isItemVisible={false}
            ownerListDataset={ownersData[dataset._id] || []}
            sharedListDataset={datasetSharedUsersList[dataset._id] || []}
          />
        }
      />
    );
  };

  return (
    <Box>
      {loading ? (
        <CircularProgress size={20} sx={{ marginTop: '10px' }} />
      ) : (
        <>
          <SearchList
            label={t('rightPanel.labelName')}
            onSelectItem={handleDatasetSelect}
            showDetailsOnHover={true}
            items={datasets}
            renderPopover={renderEditPopover}
          />
        </>
      )}
    </Box>
  );
};
export default DatasetList;
