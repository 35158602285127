import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import Theme from '../themes/Theme';
import { ReactNode } from 'react';

interface ThemeProviderProps {
  children: ReactNode;
}

const ThemeProvider = ({ children }: ThemeProviderProps) => {
  return <MuiThemeProvider theme={Theme}>{children}</MuiThemeProvider>;
};

export default ThemeProvider;
