import { useContext } from 'react';
import { Box, Tooltip } from '@mui/material';
import { ActiveNavItemContext } from '../../RightPanelContext';

// width of the button component
const WIDTH = 64;
// height of the button component
const HEIGHT = 44;

/**
 * A button that can be activated/deactivated by clicking on it.
 * @param props
 * @constructor
 */

export function NavItemButton({
  name,
  imageUrl,
  badge,
  isActive,
  clickOnNavButton,
}: {
  name: string;
  imageUrl: string;
  badge?: boolean;
  isActive: boolean;
  clickOnNavButton: (name: string) => void;
}) {
  //export function NavItemButton(props: { name: string, imageUrl: string, badge?: boolean }) {
  const { activeNavItem, setActiveNavItem } = useContext(ActiveNavItemContext);
  const active = activeNavItem === name;

  return (
    <Box>
      <Tooltip title={name.charAt(0).toUpperCase() + name.slice(1).toLowerCase()} arrow placement="left">
        <Box
          id={`nav-item-button-${name}`}
          sx={{
            marginTop: 3,
            height: HEIGHT,
            width: WIDTH,
            padding: 0,
            cursor: 'pointer',
            '&:hover': {
              backgroundPositionY: '-44px',
            },
            '&.active': {
              backgroundPositionY: '-88px',
            },
            backgroundImage: `url(${imageUrl})`,
            position: 'relative',
          }}
          className={isActive ? 'active' : ''}
          onClick={() => {
            if (!active) {
              setActiveNavItem(name);
            } else {
              setActiveNavItem(null);
            }
            clickOnNavButton(name);
          }}
        >
          <Box
            sx={{
              display: badge ? 'block' : 'none',
              position: 'absolute',
              backgroundColor: '#00E132',
              width: 8,
              height: 8,
              borderRadius: '50%',
              top: 12,
              right: 22,
            }}
          />
        </Box>
      </Tooltip>
    </Box>
  );
}
