import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../i18n';
import '../css/Login.css';
import { ExternalId } from './authConfig';
import Logo from '../components/Logo';

import { Button, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getAuthInstance, needsAuth } from '../helpers/Auth';
import { ActiveNavItemContext } from '../components/rightPanel/RightPanelContext';
import { disclaimerPageNavigation, welcomePageNavigation } from '../components/staticComponents/StaticHtmlGenerator';

export default function Login() {
  const { t } = useTranslation();
  const [externalId, setExternalId] = useState(ExternalId.tenantName);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { setActiveNavItem } = useContext(ActiveNavItemContext);

  useEffect(() => {
    const fetchAuth = async () => {
      try {
        if (needsAuth) {
          await handleLoginClick();
        }
      } catch (error) {
        console.error('Authentication failed:', error);
      }
    };

    fetchAuth();
  }, []);

  async function handleLoginClick() {
    if (loading) return; // Prevent multiple clicks
    setLoading(true);

    try {
      const authInstance = await getAuthInstance();
      setExternalId(authInstance.getExternalId());
      await authInstance.login();

      if (authInstance.isLoggedIn()) {
        const navigateDisclaimer = await authInstance.shouldShowDisclaimer();
        if (navigateDisclaimer) {
          navigate(disclaimerPageNavigation);
          await authInstance.acknowledgeDisclaimer();
        } else {
          navigate(welcomePageNavigation);
          setActiveNavItem(t('welcomepage.Home'));
        }
      }
    } catch (error) {
      console.error('Login failed:', error);
    } finally {
      setLoading(false);
    }
  }

  const handleTermsAndCondtionsTextClick = (title: string) => {
    const page = window.open('../../terms_and_conditions.html', '_blank');
    if (page) {
      page.onload = () => {
        page.document.title = title;
      };
    }
  };

  return (
    <div className="login">
      <div className="login-outer">
        <div className="d-flex login-style">
          <div className="login-l">
            <Logo externalId={externalId} />
            <div className="my-15">
              <Button
                variant="outlined"
                onClick={handleLoginClick}
                disabled={loading}
                sx={{ color: 'var(--global-color)', borderColor: 'var(--global-color)' }}
              >
                {loading ? <CircularProgress size={24} /> : t('loginButtonTitle')}
              </Button>
            </div>
            <div className="my-15">{t('or')}</div>
            <a className="signUp" href="https://www.lab45.ai/#contactus" target="_blank" rel="noreferrer">
              {t('requestAccess')}
            </a>
          </div>
          <div className="login-r">
            <h2>
              <div>{t('welcomeMessage.part1')}</div>
              <div>{t('welcomeMessage.part2')}</div>
              <br />
              <div>{t('welcomeMessage.part3')}</div>
            </h2>
            <p>{t('welcomeDescription.part1')}</p>
            <p>{t('welcomeDescription.part2')}</p>
            <p>{t('welcomeDescription.part3')}</p>
          </div>
        </div>
        <div style={{ textAlign: 'center', marginTop: '1.25rem', color: '#00000080' }}>
          <p>
            <span>{t('termsAndConditions.part1')}</span>
            <a
              onClick={() => handleTermsAndCondtionsTextClick(t('termsAndConditions.title1'))}
              style={{ color: '#256AFF', textDecoration: 'none', cursor: 'pointer' }}
            >
              {t('termsAndConditions.part2')}
            </a>
            <span>{t('termsAndConditions.part3')}</span>
            <a
              onClick={() => handleTermsAndCondtionsTextClick(t('termsAndConditions.title2'))}
              style={{ color: '#256AFF', textDecoration: 'none', cursor: 'pointer' }}
            >
              {t('termsAndConditions.part4')}
            </a>
            <span>{t('termsAndConditions.part5')}</span>
          </p>
        </div>
      </div>
    </div>
  );
}
